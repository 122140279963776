.filter_area_design {
    width: 260px;
    height: auto;
    border-radius: 8px;
    background-color: #fff;
    margin: 0 8px 0 10px;
    padding: 16px 12px;
    margin-left: 212px;
    margin-top: 82px;
}

.showning_filter_spacing .main_contain_area {
    margin-left: 0;
    width: calc(100% - 350px);
}

.filter_btn_design.btn {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    color: #757575;
    font-weight: 600;
    font-size: 14px;
}

.filter_btn_design.btn:hover {
    background-color: #eff0fe;
    color: #424242;
}

.filter_btn_design.onload_non_active.btn:hover {
    background-color: transparent;
    color: #757575;
}

.filter_btn_design.btn img {
    margin-right: 4px;
    width: 18px;
}

.custom_country_drop_design img {
    width: 14px;
}

.filter_btn_design svg {
    color: #6366F1;
}

.filter_area_design h4 {
    font-weight: 700;
    font-size: 16px;
    color: #212121;
}

.filter_area_design span {
    font-weight: 600;
    font-size: 12px;
    color: #9E9E9E;
}

.filter_area_design .accordion {
    margin-top: 12px;
}

.filter_area_design .accordion-item {
    border: none;
    border-bottom: 0.8px solid #E0E0E0;
    border-radius: 0px;
}

.filter_area_design .accordion-button {
    padding: 12px 10px;
    font-weight: 600;
    color: #212121;
    font-size: 13px;
}

.showning_filter_spacing .main_contain_area .filter_btn_design.btn {
    background-color: #6366F1;
    color: #fff;
}

.showning_filter_spacing .main_contain_area .filter_btn_design.non_active.btn {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    color: #757575;
    font-weight: 600;
    font-size: 14px;
    background-color: transparent;
}

.showning_filter_spacing .main_contain_area .filter_btn_design.fil_ter svg path {
    fill: #fff;
}

.filter_area_design .accordion-button img {
    margin-right: 7px;
}

.filter_area_design .accordion-button img {
    width: 17px;
    height: 17px;
    margin-right: 7px;
}

.filter_area_design .accordion-button .tag_clr {
    display: block;
    margin-right: 10px;
    font-weight: 600;
    font-size: 11px;
    color: #9E9E9E;
}

.filter_area_design .accordion-button::after {
    color: #E0E0E0;
    background-image: url(../assets/images/arrow.svg);
    background-size: auto;
    transform: translate(10px, 6px);
    margin: 0;
    position: absolute;
    right: 0;
}

.filter_area_design .accordion-button:not(.collapsed):after {
    background-image: url(../assets/images/arrow-up.svg);
}

.filter_area_design .accordion-button svg {
    margin-right: 9px;
    /* width: 20px; */
    transform: translateY(-1px);
}

.filter_area_design .accordion-button:focus {
    border: none;
    box-shadow: none;
}

.filter_area_design .accordion-button {
    box-shadow: none;
}

.filter_area_design .accordion-button:not(.collapsed) {
    background-color: transparent;
}

.filter_area_design .accordion-body {
    padding: 16px 10px;
    border-left: 1px solid #6366f1;
    background: #fbfbff;
}

.custom_list_boxs ul {
    margin-top: 16px;
    margin-bottom: 0;
    height: auto;
    max-height: 415px;
    padding: 0;
    position: relative;
    overflow-y: auto;
}

.custom_list_boxs ul li {
    list-style-type: none;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    width: 100%;
    cursor: pointer;
}

.custom_list_boxs ul li:last-child {
    padding: 0;
}

.custom_list_boxs ul li input {
    margin-top: 2px;
    margin-right: 8px;
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.custom_list_boxs ul li input:focus {
    box-shadow: none;
    outline: none;
}

.custom_list_boxs ul li label {
    font-weight: 400;
    font-size: 13px;
    color: #424242;
    cursor: pointer;
}

.only_for_filter_page_css .custom_list_boxs ul li label {
    line-height: normal;
    width: 180px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    border: 1px solid transparent;
}

.custom_list_boxs ul li hr {
    border: 0.8px solid #E0E0E0;
    margin: 0;
    width: 100%;
}

.custom_list_boxs ul li .form-check-input:checked {
    border-color: #6366F1;
    background-color: #6366F1;
}

.custom_list_boxs ul li .form-check-input:checked+label {
    color: #0A0A0A;
}

.filter_search_tags_design ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-content: center;
}

.filter_search_tags_design ul li {
    list-style: none;
    background-color: #fff4e2;
    border-radius: 8px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    margin-right: 8px;
}

.filter_search_tags_design ul li .dropdown-toggle.btn {
    padding: 6px 10px;
    font-weight: 600;
    color: #212121;
    display: flex;
    align-items: center;
    font-size: 12px;
}

.filter_search_tags_design ul li .dropdown-toggle.btn:active,
.filter_search_tags_design ul li .dropdown-toggle.btn:focus {
    outline: none;
    border-color: transparent;
}

.filter_search_tags_design ul li .dropdown-menu {
    width: 350px;
    height: 160px;
    overflow-y: scroll;
    transform: translateY(0px);
    border-radius: 8px;
    background-color: #FFF;
    padding: 9px 12px;
    box-shadow: 1px 1px 20px 0px rgba(60, 60, 60, 0.16);
    border: none;
}

.filter_search_tags_design ul li .dropdown-menu .dropdown-item {
    color: #616161;
    font-size: 13px;
    padding: 0 0 6px;
    font-weight: 500;
}

.filter_search_tags_design ul li .dropdown-menu .dropdown-item:last-child {
    padding: 0;
}

.filter_search_tags_design ul li .dropdown-menu:hover .dropdown-item {
    background-color: transparent;
}

.filter_search_tags_design ul li .dropdown-menu .dropdown-item .diff_txt {
    color: #6366F1;
}

.filter_search_tags_design ul li:hover .dropdown-menu {
    display: block;
}

.filter_search_tags_design ul li .dropdown-toggle::after {
    display: none;
}

.filter_search_tags_design ul li:hover {
    background-color: #ffecd1;
}

.filter_search_tags_design ul li:last-child {
    padding-left: 0;
    font-size: 12px;
    color: #f83a39;
    padding-right: 0;
    background-color: transparent;
    cursor: pointer;
}

.filter_search_tags_design ul li .dropdown-toggle.btn span {
    font-size: 12px;
    color: #fa8e02;
    padding-left: 4px;
}

.filter_search_tags_design ul li .dropdown-toggle.btn svg {
    cursor: pointer;
    margin-left: 6px;
}

.filter_search_tags_design ul li .dropdown-toggle.btn svg:hover path {
    fill: #424242;
}

.custom_list_boxs ul {
    margin-top: 16px;
    margin-bottom: 0;
    height: auto;
    max-height: 376px;
    padding: 0;
    position: relative;
    overflow-y: auto;
}

.report_page .sidebar_area {
    width: 74px;
    transition: 0.3s;
}

.report_page .top_header {
    transition: 0.3s;
    margin-left: 80px;
}

.report_page .filter_area_design {
    transition: 0.3s;
    margin-left: 92px;
}

.custom_search_bar .filter-button .form-control {
    padding: 8px 15px;
    height: 40px;
}

.custom_search_bar .filter_btn_design.btn.h-40 {
    height: 40px;
    display: flex;
    align-items: center;
}

.custom_search_bar .filter_btn_design.btn.h-40 i {
    color: #6366F1;
}

.daterangepicker td.start-date.end-date {
    background-color: #6366f1 !important;
}

.applyBtn.btn.btn-sm.btn-primary {
    background-color: #6366f1;
}

.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
    background-color: #fff !important;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
    background-color: #6366f1 !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    filter: brightness(0.5);
}

.filter_area_design .input-group-text {
    background-color: #fbfbff;
}

.filter_area_design .form-control {
    background-color: #fbfbff;
}

.font_weigth.form-control {
    font-weight: 600;
    color: #757575;
}


.daterangepicker .ranges li.active {
    background-color: #EFF0FE !important;
    border-radius: 4px;
    color: #6366f1 !important;
    font-weight: 700;
    font-size: 13px;
}

.daterangepicker .ranges li {
    font-weight: 500;
    font-size: 13px;
    color: #616161;
}

.daterangepicker .ranges li:nth-last-child(2) {
    border-bottom: 1px solid #eee;
}

.daterangepicker .ranges {
    margin: 4px !important;
}

.daterangepicker .ranges li:hover {
    background-color: #F5F5F5 !important;
    border-radius: 4px;
}

.report_data_page .main_contain_area {
    margin-bottom: 0;
}

.report_data_page .filter_area_design {
    margin-right: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


/* this css for testing component only */
.column_visibility_drop {
    position: absolute;
    right: 137px;
    top: 102px;
}

.dimension_text_icon {
    position: absolute;
    right: 185px;
    z-index: 9;
    top: 114px;
    color: #757575;
    font-weight: 600;
    font-size: 14px;
}

.dimension_text_icon svg {
    margin-right: 5px;
    transform: translateY(-1px);
}

.new_tree_drop-design .ant-select-selector {
    width: 156px;
    height: 40px;
    position: relative;
    overflow: hidden;
}

.new_tree_drop-design .ant-select-selection-item {
    background-color: #fff;
    border: 1px solid #fff;
    color: #fff;
    margin-right: 4px;
    height: 30px;
    display: flex;
    align-items: center;
    display: none;
}

/* this css for only report group accordion design */
.mini_loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffffad;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mini_loader svg {
    font-size: 30px;
}

.mini_loader svg path {
    fill: #6366f1;
    font-size: 20px;
}

.dimensions_drop_down .ant-select-dropdown.ant-tree-select-dropdown {
    width: 260px !important;
    box-shadow: 0px 2px 20px rgba(51, 51, 51, 0.08);
    border: none;
}

.dimensions_drop_down .ant-select-tree-list-scrollbar-thumb {
    width: 2px !important;
    background: #e0e0e0 !important;
    border-radius: 100px !important;
    border-radius: 100px !important;
}

.non_custom_date_width_css {
    width: 100px !important;
}

.custom_date_width_css {
    width: 173px !important;
}

.drp-selected {
    display: none !important;
}