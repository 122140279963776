* {
  padding: 0;
  margin: 0;
  font-family: "Nunito", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

body {
  background-color: #f4f7fc;
}

.no_data_colour {
  color: #979797 !important;
}

.hyper_link_clr {
  color: #6366f1 !important;
}

.disabled_text {
  color: #616161;
}

.text_hover_colour:hover {
  color: #4547a9;
}

.text_hover_colour:hover svg path {
  fill: #4547a9;
}

.text_hover_colour:hover .hover_icon path {
  fill: #4547a9;
}

.cursor_pointer {
  cursor: pointer;
}

.fixed_colour {
  color: #9e9e9e !important;
  font-size: 12px !important;
}

.green_text {
  color: #008500;
}

.red_text {
  color: #F83A39 !important;
}

.hyper_text_link_clr {
  color: #3b3d91;
}

.alert_text {
  color: #fd2018;
  display: block;
  font-size: 12px;
  padding-top: 4px;
  position: absolute;
}

/* login form css */

.fixed_logo {
  position: absolute;
  top: 0;
  left: 0;
}

.custom_container {
  width: 100%;
  /* max-width: 1825px; */
  height: 100%;
  margin: auto;
  display: block;
}

.login_page {
  padding: 36px;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
}

.for_center_div {
  display: grid;
  place-items: center;
}

.form_fields {
  padding: 0px;
  width: 400px;
  margin: 0 auto;
}

.form_fields .login_between_space {
  margin-bottom: 20px;
}

.form_fields h1 {
  font-weight: 700;
  font-size: 32px;
  margin: 0;
  color: #212121;
  padding-bottom: 6px;
}

.form_fields p {
  font-weight: 400;
  padding-bottom: 40px;
  font-size: 16px;
  color: #9e9e9e;
}

.form_fields label {
  font-weight: 600;
  font-size: 12px;
  color: #424242;
  margin: 0;
  padding-bottom: 4px;
}

.form_fields .form-control::placeholder {
  color: #bdbdbd;
  font-weight: 400;
  font-size: 14px;
}

::placeholder {
  color: #bdbdbd;
  font-weight: 400;
  font-size: 14px;
}

.ant-select-selection-placeholder,
.css-1jqq78o-placeholder {
  color: #bdbdbd !important;
  font-weight: 400;
  font-size: 14px;
}

.form_fields .form-control:focus {
  box-shadow: none;
  border: 1px solid #6366f1;
  outline: 4px solid #eff0fe;
  outline-offset: initial;
}

.form_fields .form-control {
  border: 1px solid #e0e0e0;
  height: 44px;
  font-size: 14px;
  border-radius: 8px;
  padding: 12px 40px;
  font-weight: 500;
}

.form_fields .abso_element_left {
  border: none;
  position: absolute;
  left: 12px;
  top: 12px;
  font-size: 16px;
  color: #bdbdbd;
}

.form_fields .eye_abso_element_left {
  left: 368px;
  cursor: pointer;
}

.form_fields .form-control:focus .abso_element_left {
  color: red;
}

.form_fields .abso_element_right {
  border: none;
  padding: 4px 0 0;
  text-align: right;
  display: block;
}

.form_fields a {
  font-weight: 500;
  font-size: 12px;
  padding-top: 4px;
  text-decoration: none;
}

.form_fields .btn.btn_button {
  width: 100%;
  background: #6366f1;
  box-shadow: 0px 4px 18px rgba(99, 102, 241, 0.4);
  height: 44px;
  margin: 30px 0 16px;
  color: #ffffff;
  font-weight: 700;
  display: flex;
  font-size: 14px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
}

.form_fields .btn.btn_button:hover {
  background: #595CD9;
}

.form_fields .btn.btn_button:focus {
  background: #4547A9;
  color: #7375F2;
}

.form_fields span.sign_up_text {
  display: block;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  color: #757575;
}

.form_fields span.sign_up_text a {
  font-size: 14px;
  font-weight: 600;
}

.ant-carousel .slick-dots-bottom {
  bottom: -10px;
}

.ant-carousel .slick-dots li button {
  background-color: #bdbdbd;
  height: 10px;
  width: 10px;
  border-radius: 100px;
}

.ant-carousel .slick-dots li.slick-active button {
  background-color: #6366f1;
  opacity: 1;
  width: 24px;
}

/* login slider css */

.login_img_area {
  background-image: linear-gradient(136deg, rgba(82, 34, 255, 0.12) 0%, rgba(0, 163, 182, 0.12) 54.87%, rgba(41, 195, 138, 0.12) 99.48%);
  /* width: 900px; */
  width: 100%;
  height: 100%;
  padding: 47px;
  box-sizing: border-box;
  border-radius: 12px;
}

.login_img_area img {
  width: 641px;
  margin: auto;
}

.login_img_area p {
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  padding: 40px 170px 40px;
  text-align: center;
  color: #424242;
}

.login_img_area .slick-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  background-color: white;
}

.no_table_data {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.no_table_data h2 {
  color: #212121;
  font-size: 16px;
  font-weight: 600;
  margin-top: 28px;
}

.no_table_data p {
  font-weight: 400;
  font-size: 13px;
  margin: 0;
  padding-top: 4px;
  color: #bdbdbd;
}