.mt-20 {
  margin-top: 20px;
}

small {
  color: #616161;
  font-weight: 500;
}

.green_text {
  font-weight: 500;
  color: #008500;
}

.blue_text {
  font-weight: 600;
  color: #7375f2;
}

.fs-12 {
  font-size: 12px;
}

.cursor-pointer {
  cursor: pointer;
}

.step_form_label p {
  font-weight: 400;
  font-size: 14px;
  color: #9e9e9e;
  padding: 8px 30px 0 0;
}




.campaign_box_area label {
  cursor: pointer;
  width: 100%;
  position: relative;
  /* overflow: hidden; */
}

.step_input_fields .campaign_box_area.diff_radio_box_with_content label {
  font-size: 14px;
  color: #9e9e9e;
}

.step_input_fields .campaign_box_area.diff_radio_box_with_content label {
  font-size: 14px;
  color: #9e9e9e;
}

.campaign_box_area input[type="radio"] {
  position: absolute;
  left: 15px;
  top: 14px;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin: 0;
  -webkit-appearance: none;
}

.conversion_radio.campaign_box_area input[type="radio"] {
  top: 11px;
}

.step_input_fields .campaign_box_area.diff_radio_box_with_content input[type="radio"]:checked {
  color: red !important;
}


.diff_campaign_box .campaign_box_area input[type="radio"] {
  top: 11px;
}

.campaign_box_area input[type="checkbox"] {
  position: absolute;
  top: 14px;
  left: 15px;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  width: 18px;
  -webkit-appearance: none;
  height: 18px;
  margin: 0;
}

.form-check-input {
  width: 16px;
  border: 1px solid #d9d9d9;
  height: 16px;
  margin: 0;
}

.campaign_box_area .form-check-input:checked {
  background-color: #6366f1;
  border: 3px solid #a1a3f7;
}

.campaign_box_area .form-check-input:focus {
  box-shadow: none;
}

.campaign_box_area .form-check-input:checked[type="radio"] {
  background-image: none;
  border: 1px solid #a1a3f7;
  background-color: #fff;
  border-radius: 100px;
  -webkit-appearance: none;
}

.campaign_box_area .form-check-input[type="radio"]:checked+span.three_text {
  color: #0a0a0a;
}

.campaign_box_area .form-check-input[type="radio"]:checked+span.three_text {
  color: #0a0a0a;
}

.campaign_box_area .form-check-input:checked[type="checkbox"] {
  background-image: none;
  border: 2px solid #a1a3f7;
  background-color: #fff;
  border-radius: 4px;
  -webkit-appearance: none;
}

.step_input_fields .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  border-color: #d9d9d9;
  box-shadow: none;
}

.step_input_fields .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):focus .ant-select-selector {
  border-color: #6366f1;
  box-shadow: none;
}

.campaign_box_area .form-check-input:checked[type="radio"]::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  background-color: #6366f1;
  border-radius: 100px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.campaign_box_area .form-check-input:checked[type="checkbox"]::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  background-color: #6366f1;
  border-radius: 2px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.campaign_box {
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  padding: 0 20px;
}

.diff_campaign_box .campaign_box {
  border: 1px solid #E0E0E0;
}

.campaign_box_area:hover .campaign_box {
  background-color: #eff0fe;
  border: 1px solid #d0d1fb;
}

.campaign_box_area.campaign_box_area_disabled {
  position: relative;
  overflow: hidden;
}

.campaign_box_area.campaign_box_area_disabled .campaign_box {
  background-color: transparent;
  border: 1px solid #f5f5f5;
}

.campaign_box_area.campaign_box_area_disabled:hover .campaign_box {
  background-color: #fff;
  border: 1px solid #eeeeee;
}

/* .campaign_box_area.campaign_box_area_disabled .campaign_box h3 {
  color: #949494;
} */

.campaign_box_area.campaign_box_area_disabled .campaign_box h4 {
  color: #fff;
  background-color: #6366F1;
  width: 100%;
  height: 23px;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 24px;
  top: 22px;
  transform: rotate(45deg);
}

.campaign_box_area .campaign_box img {
  transition: 0.3s;
  width: 58px;
  margin: 17px auto 0px;
}

.campaign_box_area .campaign_box.for_margin_top_cls img {
  margin: 24px auto 0px;
}

.campaign_box_area .campaign_box img.img_one {
  display: block;
}

.campaign_box_area .campaign_box img.img_two {
  display: none;
}

.campaign_box_area:hover .campaign_box img.img_one {
  transition: 0.3s;
  display: none;
}

.campaign_box_area:hover .campaign_box img.img_two {
  transition: 0.3s;
  display: block;
}

.campaign_box_area .campaign_box h3 {
  font-weight: 600;
  font-size: 14px;
  color: #212121;
  padding: 19px 0 4px;
}

.campaign_box_area .campaign_box p {
  font-weight: 400;
  font-size: 14px;
  transform: translateY(-1px);
  color: #9e9e9e;
}

.campaign_box_area input[type="radio"]:checked~.campaign_box {
  background-color: #eff0fe;
  border: 1px solid #6366f1;
  transition: 0.3s;
}

.campaign_box_area input[type="radio"]:checked~.campaign_box p {
  color: #212121;
  font-weight: 500;
}

.campaign_box_area input[type="checkbox"]:checked~.campaign_box {
  background-color: #eff0fe;
  border: 1px solid #6366f1;
  transition: 0.3s;
}

.campaign_box_area input[type="radio"]:checked~.campaign_box img.img_one {
  display: none;
}

.campaign_box_area input[type="radio"]:checked~.campaign_box img.img_two {
  display: block;
}

.campaign_box_area.campaign_box_area_hover_disabled:hover .campaign_box {
  background-color: #fff;
  border: 1px solid #E0E0E0;
  cursor: default;
}

.second_step_area .campaign_box {
  padding: 10px;
  height: 40px;
}

.second_step_area .ant-picker-range {
  position: relative;
  display: inline-flex;
  width: 100%;
  height: 44px;
}

.week_table tbody tr td {
  border-left: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
}

.week_table tbody tr:last-child td {
  border-bottom: none;
}

.custom_table_checkbox {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
}

.week_table tbody tr td:first-child {
  width: 87px;
  text-align: left;
  border-left: none;
  /* border-bottom: none; */
}

.step_input_fields .custom_table_checkbox .week_table label {
  font-size: 12px;
  margin: 0;
  color: #fff;
}

.week_table_text {
  color: #757575;
  font-size: 12px;
  font-weight: 600;
  margin: 0;
  padding-top: 4px;
}

/* .week_table tbody tr td:last-child {
  border-left: none;
  border-bottom: none;
} */

.week_table tbody td {
  font-size: 12px;
  position: relative;
  padding: 3px 4px;
  text-align: center;
}

.week_table tbody td input[type="checkbox"] {
  position: absolute;
  width: 100%;
  left: 0;
  cursor: pointer;
  height: 100%;
  top: 0;
  margin: 0;
  z-index: 9;
  opacity: 0;
}

.week_table tbody td input[type="checkbox"]:checked~label::before {
  background-color: #56a8ec;
  border-radius: 2px;
  position: absolute;
  left: 3px;
  top: 3px;
  content: "";
  width: 19px;
  height: 19px;
}

.week_table tbody td input[type="checkbox"]:checked~span {
  color: #0a0a0a;
  font-weight: 600;
}

.week_table tbody td span {
  color: #757575;
}

.row_selection {
  color: #757575;
  padding: 0 4px;
}

.week_table tbody td input[type="checkbox"]:checked~.row_selection::before {
  background-color: #56a8ec;
  border-radius: 2px;
  position: absolute;
  left: 4px;
  top: 3px;
  content: "";
  width: 81px;
  opacity: 0.3;
  height: 19px;
}

.week_table tbody td input[type="checkbox"]:checked~span::before {
  background-color: #56a8ec;
  border-radius: 2px;
  position: absolute;
  left: 3px;
  top: 3px;
  content: "";
  opacity: 0.3;
  width: 19px;
  height: 19px;
}

.select-pos {
  position: relative;
}

.select2-design {
  left: 12px;
  position: absolute;
  z-index: 1;
  top: 9px;
  background: #fff;
  font-size: 14px;
}

.select-pos {
  position: relative;
}

.css-t3ipsp-control {
  height: 40px;
}

.custom_form_select .css-t3ipsp-control {
  width: 355px;
}

.input-group-text.custom_disabled,
.form-control:disabled {
  background-color: #f5f5f5 !important;
}

.css-1u9des2-indicatorSeparator {
  background-color: transparent !important;
}

.css-tj5bde-Svg {
  color: #bdbdbd;
}

.custom_form_select .css-d7l1ni-option,
.custom_form_select .css-tr4s17-option,
.css-d7l1ni-option,
.custom_form_select .css-tr4s17-option {
  font-size: 14px;
}

.custom_form_select .css-13cymwt-control,
.custom_form_select .css-13cymwt-control:hover {
  box-shadow: none !important;
  border-color: #e0e0e0 !important;
  font-size: 14px;
}

.custom_form_select.custom_form_select_border .css-13cymwt-control {
  border-right: transparent;
  border-left: transparent;
}

.custom_form_select .css-d7l1ni-option,
.custom_form_select .css-tr4s17-option,
.custom_form_select .css-d7l1ni-option:hover,
.custom_form_select .css-d7l1ni-option:active {
  background-color: #b2d4ff;
  font-size: 14px;
}

.css-t3ipsp-control {
  border-color: #e0e0e0 !important;
  box-shadow: none !important;
}

.css-t3ipsp-control:hover {
  border-color: #e0e0e0;
  box-shadow: none !important;
}

.css-t3ipsp-control:focus {
  box-shadow: none;
  border: 1px solid #6366f1;
  outline: 4px solid #eff0fe;
}

.custom_form_select .css-10wo9uf-option {
  font-size: 14px;
}

.css-10wo9uf-option,
.css-tr4s17-option,
.css-d7l1ni-option {
  font-size: 14px !important;
}

.custom_form_select .css-13cymwt-control {
  height: auto;
  min-height: 44px;
  font-size: 14px;
  width: 355px;
}

.css-1nmdiq5-menu {
  z-index: 99 !important;
  margin-top: 1px !important;
}

.css-1nmdiq5-menu input {
  margin: 0 10px 0 0;
}

.css-tr4s17-option {
  background-color: transparent !important;
  color: #000 !important;
}

.value_text {
  left: -1px;
  position: absolute;
  z-index: 9;
  top: 1px;
  background: #fff;
  width: 150px;
  font-weight: 600;
  color: #0a0a0a;
  font-size: 14px;
}

.select-pos {
  position: relative;
}

/* .select-pos:hover .select2-design {
  display: none;
} */

.gray_btn_design .btn {
  background-color: #f5f5f5;
  height: 36px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  width: 100%;
  color: #616161;
  font-size: 13px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 10px;
}

.simple_radio .campaign_box_area input[type="radio"] {
  top: 2px;
  left: -24px;
  width: 18px;
  height: 18px;
}

.simple_radio .campaign_box_area input[type="checkbox"] {
  top: 4px;
  left: -24px;
  width: 18px;
  height: 18px;
}

.input_border_design {
  height: 44px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 14px;
}

.code_showing_area {
  background: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 12px;
}


.code_showing_area pre {
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  color: #2c6ab7;
  font-family: "Nunito", sans-serif !important;
}

#map {
  width: auto !important;
  margin-top: 12px;
  height: 500px !important;
}

#map:first-child {
  height: 96% !important;
}

#map .gm-style {
  overflow: hidden;
  border-radius: 8px;
}

.creative_table.table thead tr th {
  font-weight: 600;
  font-size: 13px;
  color: #687182;
}

.creative_table.table .custom_table_thead {
  background: rgba(247, 249, 252, 0.8);
}

.creative_table.custom_data_table tbody tr:last-child {
  border-color: transparent;
}

.creative_table.table thead tr th input[type="checkbox"] {
  font-size: 16px;
  margin: 0;
}

.creative_table.table tbody tr td input[type="checkbox"] {
  font-size: 15px;
}

.creative_table table tbody tr td {
  font-weight: 500;
  font-size: 13px;
  color: #212121;
}

.css-t3ipsp-control,
.css-16xfy0z-control,
.css-13cymwt-control {
  height: auto !important;
  min-height: 40px !important;
  border-color: #e0e0e0 !important;
  border-radius: 8px !important;
}

.textarea_dropdown_design .css-16xfy0z-control,
.textarea_dropdown_design .css-t3ipsp-control,
.textarea_dropdown_design .css-13cymwt-control {
  height: auto !important;
  min-height: 60px !important;
}

.textarea_dropdown_design input.form-control {
  height: 60px;
}

.custom_country_drop_design.diff-disabled_cls .css-16xfy0z-control {
  border-radius: 0 8px 8px 0 !important;
  border-left: none;
  min-height: 30px !important;
}

.graph_areas_side .custom_country_drop_design.diff-disabled_cls .input-group-text {
  background-color: #f2f2f2;
  padding: 0 6px 0 10px;
  border-color: #e0e0e0;
}

.css-t3ipsp-control:focus {
  border: 1px solid red;
}

.css-12a83d4-MultiValueRemove:hover {
  background-color: transparent !important;
}

.css-1jqq78o-placeholder,
.css-olqui2-singleValue,
.css-1dimb5e-singleValue {
  font-size: 14px;
}

.custom_container_form .ant-select-selection-item {
  background-color: #E4F2FC;
  border: 1px solid #71B8EF;
  color: #2C6AB7;
  margin-right: 4px;
  height: 30px;
  display: flex;
  align-items: center;
}

.css-13cymwt-control:hover {
  border-color: #e0e0e0 !important;
}

.css-t3ipsp-control:focus {
  border-color: red !important;
}

.joinet_input_fields .css-t3ipsp-control,
.joinet_input_fields .css-13cymwt-control {
  border-radius: 0 !important;
}

.joinet_input_fields .css-13cymwt-control:hover {
  border-color: #e0e0e0 !important;
}

.joinet_input_fields .first_react_select .css-t3ipsp-control,
.joinet_input_fields .first_react_select .css-13cymwt-control {
  border-radius: 8px 0 0 8px !important;
}

.joinet_input_fields .first_react_select .css-13cymwt-control:hover {
  border-color: #e0e0e0 !important;
}

.creative_img .campaign_box_area .campaign_box img {
  width: 36px;
  height: 36px;
}

.ant-select-selector {
  height: auto;
  min-height: 40px;
}

.ant-select {
  height: auto;
  min-height: 40px;
}

.after_upload_img {
  border: 1px dashed #e0e0e0;
  border-radius: 6px;
  position: relative;
  margin-right: 10px;
  padding: 14px;
  margin-top: 10px;
}

.after_upload_img .right-border {
  font-size: 12px;
  color: #212121;
}

.after_upload_img .img_file_name {
  font-size: 12px;
  color: #212121;
}

.after_upload_img .file_support_text {
  font-size: 14px;
  color: #f11414;
}

.after_upload_img .close_img_btn {
  position: absolute;
  right: 14px;
  top: 9px;
  color: #f11414;
  cursor: pointer;
}

.url_list_design {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 10px;
}

.url_list_design table {
  color: #424242;
}

.url_list_design table i {
  font-size: 7px;
  padding-right: 8px;
  color: #424242;
}

.url_list_design table tr td {
  font-size: 13px;
  font-weight: 400;
  color: #424242;
  padding: 0;
}

.icon_gray_color {
  color: #BDBDBD;
  cursor: pointer;
}

.upload_btn_design .btn {
  background: #F5F5F5;
  border: 1px solid #EEEEEE;
  border-radius: 6px;
  font-weight: 700;
  font-size: 12px;
  color: #424242;
  width: 81px;
  padding: 8px 12px;
}

.upload_btn_design .btn:active {
  color: #424242;
  border: 1px solid #EEEEEE;
}

.upload_btn_design .btn.dropdown-toggle::after {
  color: #BDBDBD;
  margin-left: 6px;
}

.upload_btn_design .dropdown-menu {
  padding: 4px;
  font-weight: 500;
  font-size: 13px;
  color: #616161;
  box-shadow: 1px 1px 20px rgba(60, 60, 60, 0.16);
  border: none;
}

.upload_btn_design .dropdown-item {
  padding: 4px 8px;
  margin: 4px 0;
  border-radius: 4px;
}

.upload_btn_design .dropdown-item:active,
.upload_btn_design .dropdown-item:focus {
  background: #EFF0FE;
  font-weight: 700;
  color: #6366F1;
}

.upload_btn_design .dropdown-item:hover {
  background: #F5F5F5;
}

.link_modal_popup .modal-title {
  font-weight: 400;
  color: #0A0A0A;
  font-size: 16px;
}

.ant-select-multiple .ant-select-selection-item-remove svg {
  transform: translate(0px, -2px);
  margin-right: 4px;
}

.ant-select-multiple .ant-select-selection-item-content {
  text-transform: lowercase;
  margin-inline-end: 6px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 8px;
  border: 1px solid #e0e0e0;
}

.video-react-controls-enabled {
  width: 100% !important;
}

.custom_drop_design .css-1nmdiq5-menu {
  margin-top: 8px !important;
  box-shadow: 1px 1px 20px 0px rgba(60, 60, 60, 0.16);
}

.custom_drop_design .css-t3ipsp-control {
  border: 1px solid #6366f1 !important;
  outline: 4px solid #eff0fe !important;
}

.custom_drop_design .css-1o41ekm,
.custom_drop_design .css-qr46ko,
.custom_drop_design .css-v8m8nc-MenuList,
.custom_drop_design .css-e8atq5-MenuList,
.custom_drop_design .css-1n6sfyn-MenuList {
  padding: 4px;
}

.custom_drop_design .css-1o41ekm .css-d7l1ni-option,
.custom_drop_design .css-1o41ekm .css-10wo9uf-option,
.custom_drop_design .css-qr46ko .css-d7l1ni-option,
.custom_drop_design .css-qr46ko .css-10wo9uf-option,
.custom_drop_design .css-1n6sfyn-MenuList .css-d7l1ni-option,
.custom_drop_design .css-1n6sfyn-MenuList .css-10wo9uf-option {
  border-radius: 4px !important;
  font-size: 14px !important;
  padding: 5px 8px;
  font-weight: 500;
}

.custom_drop_design .css-1o41ekm .css-tr4s17-option,
.custom_drop_design .css-1o41ekm .css-d7l1ni-option,
.custom_drop_design .css-qr46ko .css-tr4s17-option,
.custom_drop_design .css-qr46ko .css-d7l1ni-option,
.custom_drop_design .css-v8m8nc-MenuList .css-d7l1ni-option,
.custom_drop_design .css-v8m8nc-MenuList .css-tr4s17-option {
  border-radius: 4px !important;
  font-size: 14px !important;
  padding: 5px 8px;
}

.custom_drop_design .css-1o41ekm .css-10wo9uf-option,
.custom_drop_design .css-qr46ko .css-10wo9uf-option,
.custom_drop_design .css-v8m8nc-MenuList .css-10wo9uf-option {
  padding: 5px 8px;
}

.custom_drop_design .css-1o41ekm .css-d7l1ni-option,
.custom_drop_design .css-qr46ko .css-d7l1ni-option,
.custom_drop_design .css-v8m8nc-MenuList .css-d7l1ni-option,
.custom_drop_design .css-1n6sfyn-MenuList .css-d7l1ni-option {
  background-color: #f5f5f5 !important;
}

.custom_drop_design .css-qr46ko .css-d7l1ni-option,
.custom_drop_design .css-1n6sfyn-MenuList .css-d7l1ni-option {
  background-color: transparent;
}

.custom_drop_design .css-1o41ekm .css-tr4s17-option,
.custom_drop_design .css-qr46ko .css-tr4s17-option,
.custom_drop_design .css-v8m8nc-MenuList .css-tr4s17-option,
.custom_drop_design .css-1n6sfyn-MenuList .css-tr4s17-option {
  border-radius: 4px !important;
  padding: 5px 8px;
  color: #6366f1 !important;
  font-weight: 600;
  background-color: #eff0fe !important;
}

.custom_drop_design .css-1n6sfyn-MenuList .css-d7l1ni-option:hover {
  background-color: #f5f5f5;
}

.add_tri_point {
  color: #6366f1;
  font-size: 14px;
  font-weight: 600;
}

.add_tri_point:hover {
  color: #595CD9;
}

.delete_row_icon svg {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.delete_row_icon svg:hover path {
  fill: #fc1b13;
}

.text_area_input_fields textarea.form-control {
  border-radius: 0 8px 8px 0 !important;
  font-family: "Nunito", sans-serif !important;
}

.text_area_input_fields textarea.form-control::-webkit-scrollbar-thumb,
.text_area_input_fields textarea.form-control::-webkit-scrollbar-track {
  background-color: transparent;
  font-family: "Nunito", sans-serif !important;
}

.diff_value_input {
  color: #212121 !important;
  font-weight: 500 !important;
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #595CD9;
}

.only_slider .modal-dialog {
  max-width: fit-content;
  margin-right: auto;
  margin-left: auto;
}

.only_slider .modal-content {
  width: 1400px;
  height: 94vh;
  /* min-width: 800px;
  max-width: 1500px; */
  /* min-height: 600px;
  max-height: 1500px; */
}

.only_slider .modal-body .carousel .carousel-control-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -130px;
}

.only_slider .modal-body .carousel .carousel-control-prev {
  position: absolute;
  top: 50%;
  left: -130px;
  transform: translateY(-50%);
}

.ant-tree-select-dropdown .ant-select-tree .ant-select-tree-checkbox {
  margin-inline-end: 4px;
}

.react-datepicker__close-icon {
  display: none !important;
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.react_check_box_drop {
  display: flex;
  align-items: center;
}

.upload_logo_area p.file_name {
  color: #2C6AB7;
  font-size: 12px;
  font-weight: 500;
}

.upload_logo_area p.file_name img {
  width: 22px;
  height: 22px;
  margin-right: 7px;
}

.img_size_clr {
  color: #212121;
}

.select-custom.pagination_select .css-t3ipsp-control,
.select-custom.pagination_select .css-16xfy0z-control,
.select-custom.pagination_select .css-13cymwt-control {
  border: 1px solid #EEEEEE !important;
  box-shadow: none !important;
  outline: none !important;
  min-height: 32px !important;
}

.css-t3ipsp-control,
.css-16xfy0z-control,
.css-13cymwt-control {}

.select-custom.pagination_select .css-13cymwt-control .css-1hb7zxy-IndicatorsContainer .css-1xc3v61-indicatorContainer,
.select-custom.pagination_select .css-t3ipsp-control .css-1hb7zxy-IndicatorsContainer .css-15lsz6c-indicatorContainer {
  padding: 0 !important;
  width: 30px !important;
  display: block !important;
}

.select-custom.pagination_select .css-13cymwt-control .css-1hb7zxy-IndicatorsContainer .css-1xc3v61-indicatorContainer svg,
.select-custom.pagination_select .css-t3ipsp-control .css-1hb7zxy-IndicatorsContainer .css-15lsz6c-indicatorContainer svg {
  transform: translate(-1px, -1.5px);
}

.select-custom.pagination_select .css-t3ipsp-control .css-1dimb5e-singleValue,
.select-custom.pagination_select .css-13cymwt-control .css-1dimb5e-singleValue {
  font-size: 12px !important;
}

.two_custom_datepicker_design .react-datepicker__navigation--previous {
  left: 9px;
  top: 9px;
}

.two_custom_datepicker_design .react-datepicker__navigation--next {
  right: 9px;
  top: 9px;
}

.diff_text_small {
  font-size: 12px;
  font-weight: 400;
  color: #9e9e9e;
  display: block;
}

.intro_icon {
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 12px;
  cursor: pointer;
}

.intro_icon svg:hover path {
  fill: #424242;
}


.step_one_data_info img {
  /* margin-bottom: 50px; */
  width: 58%;
  margin-bottom: 12px;
}

.step_one_data_info img.first_frame {
  height: 58%;
  width: auto;
  margin-bottom: 40px;
}

.step_one_data_info h2 {
  color: #212121;
  font-size: 16px;
  /* padding-top: 18px; */
  font-weight: 700;
}

.step_one_data_info p {
  color: #757575;
  font-size: 13px;
  font-weight: 500;
  height: 90px;
  padding: 12px 0px 0px;
  width: 80%;
  margin: 0 auto 10px;
}

.step_one_data_info ul {
  margin: 0;
  padding: 0;
  display: flex;
}

.step_one_data_info ul li {
  width: 8px;
  height: 8px;
  list-style-type: none;
  margin: 0 3px;
  border-radius: 100px;
  background-color: #e0e0e0;
}

.step_one_data_info ul li.active_ball {
  width: 14px;
  background-color: #6366f1;
}

/* .step_one_data_info ul li:last-child {
  margin-right: 0;
} */

.tour_modal_popup .modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tour_modal_popup .modal-content {
  width: 680px;
  height: 458px;
  display: block;
  background-color: #fefcfe;
}

.tour_modal_popup .modal-body {
  padding: 0 20px 20px;
  height: 100%;
  display: flex;
  align-items: end;
  justify-content: center;
}

.tour_modal_popup .btn-close {
  position: absolute;
  z-index: 9;
  right: 16px;
  top: 16px;
}

.custom_tooltip_design {
  position: absolute;
  left: -85px;
  top: -41px;
  background-color: rgb(0 0 0);
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  width: 185px;
  height: 34px;
  padding: 6px 8px;
  transition: 0.9s;
}

.custom_tooltip_design::before {
  content: '';
  position: absolute;
  left: 50%;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  bottom: -4px;
  background-color: rgb(0 0 0);
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  border-radius: 2px;
  z-index: -1;
}

.custom_tooltip_design p {
  font-size: 14px;
  text-align: center;
  color: #fff;
}

.custom_tooltip_design.hidedive {
  /* display: none; */
  opacity: 0;
  transition: 0.6s;
}

.btn-close:focus {
  box-shadow: none !important;
}

.new_scroll_for_footer {
  height: calc(100vh - (231px));
  height: -webkit-calc(100vh - (231px));
  overflow: scroll;
}

.footer_edit_button {
  width: 100%;
  background-color: #fff;
  height: auto;
  padding: 12px 21px;
  position: absolute;
  left: 0;
  z-index: 9;
  display: flex;
  justify-content: end;
  border-top: 2px solid #eff0fe;
  /* box-shadow: 0px -2px 24px 0px rgba(22, 22, 22, 0.08); */
}

.input_devider_line_css {
  width: 8px;
  height: 1px;
  background-color: #BDBDBD;
  margin: 0 4px;
}

.add_more_input_btn {
  margin-top: 4px;
}

.add_more_input_btn button.btn {
  color: #7375F2;
  display: flex;
  align-items: center;
  padding: 0;
  font-size: 12px;
  font-weight: 600;
  line-height: 16.37px;
}

.add_more_input_btn button.btn svg {
  margin-right: 4px;
}

.add_more_input_btn button.btn:active,
.add_more_input_btn button.btn:focus {
  border-color: transparent;
  box-shadow: none;
}

.add_more_input_btn button.btn:hover {
  color: #4547a9;
}

.add_more_input_btn button.btn:hover svg path {
  fill: #4547a9;
}

.age_delete_btn_icon.btn:focus {
  box-shadow: none;
  border-color: transparent;
}

.size_heading {
  margin-top: 22px;
  justify-content: center;
}

.size_heading span {
  color: #9e9e9e;
  font-size: 12px;
  line-height: normal;
  font-weight: 500;
  white-space: nowrap;
}

.inside_creative_size_placeholder {
  display: flex;
  flex-wrap: wrap;
  gap: 0;
  text-align: left;
  margin-left: 20px;
}

.inside_creative_size_placeholder span {
  color: #9e9e9e;
  font-size: 11px;
  line-height: normal;
  font-weight: 500;
  margin: 0;
  padding: 0 0px 10px 0;
  width: 57px;
  margin-right: 20px;
}

.new_file_upload_design.new_file_upload_design_height {
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 24px 14px;
}

.creative_design_line {
  border-color: #E0E0E0;
  width: 100%;
  opacity: 1;
  height: 0.8px;
  margin: 20px 0;
}


/* ///////////////////////aayush//////////////////// */
.gm-control-active {
  position: absolute;
  left: 0%;
}

.gm-style-mtc-bbw {
  display: none;
}

.map_button_campaign {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 6;
  border-radius: 8px;
  background: var(--Blue-Hepatica-400-Primary, #6366F1);
  box-shadow: 0px 4px 18px 0px rgba(12, 12, 12, 0.40);
  display: flex;
  width: 113px;
  height: 36px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.map_textarea_height {
  min-height: 144px !important;
  height: 144px;
  color: #000;
  font-weight: 400;
  font-size: 14px;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
}

.button_map_modal {
  width: 90px;
  /* margin-top:20px; */
  margin-left: 10px;
  justify-content: center;
}

.min_height_60 {
  min-height: 60px !important;
}

.edit_brand_lift_cls .campaign_box_area:hover .campaign_box {
  background-color: transparent;
  border: 1px solid #E0E0E0;
  cursor: no-drop;
}

.edit_brand_lift_cls .w-896 {
  cursor: no-drop;
}

.edit_brand_lift_cls .w-440 {
  cursor: no-drop;
}

.edit_brand_lift_cls .no_drop_cursor {
  cursor: no-drop;
}

.edit_brand_lift_cls .survey_preview_area ul {
  cursor: no-drop;
}

.edit_brand_lift_cls .input-group-text.custom_disabled,
.edit_brand_lift_cls .form-control:disabled {
  cursor: no-drop;
}

.edit_capping_type .campaign_box_area:hover .campaign_box {
  background-color: #fff;
  border: 1px solid #E0E0E0;
  cursor: no-drop;
}