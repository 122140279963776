.top_header {
  width: -webkit-fill-available;
  width: -moz-available;
  padding: 0px 12px;
  background-color: #ffffff;
  position: fixed;
  margin-left: 200px;
  height: 68px;
  z-index: 999;
  transition: all 0.2s, width 0s;
  top: 0;
  right: 0;
}

.top_header .profile_area .dropdown-toggle {
  border: none;
}

.top_header .profile_area .dropdown-toggle::after {
  display: none;
}

.top_header .profile_area img.main_profile {
  object-fit: cover;
  border-radius: 4px;
  height: 24px;
  transform: translateY(-2px);
}

.top_header .profile_area img.main_profile {
  border-radius: 4px;
  height: 30px;
}

.top_header .profile_area .profile_drop_menu {
  display: flex;
  align-content: center;
  padding-top: 6px;
}

.top_header .profile_area .dropdown-menu {
  width: auto;
  min-width: 218px;
  box-shadow: 1px 1px 20px rgba(60, 60, 60, 0.16);
  padding: 0;
  border-radius: 8px;
  border: none;
}

.name_placeholder {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background-color: #eff0fe;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.name_placeholder.hover_effect_user_profile:hover {
  background-color: #d0d1fb;
  transition: 0.3s;
}

.name_placeholder span {
  font-weight: 500;
  font-size: 18px;
  display: block;
  text-align: center;
  color: #616161;
  line-height: 0px;
  text-transform: uppercase;
  transition: 0.3s;
}

.name_placeholder.hover_effect_user_profile:hover span {
  transition: 0.3s;
  color: #424242;
}

.top_header .profile_area .dropdown-divider {
  margin: 0;
  border-top: 1px solid #D4D7E166;
}

.top_header .profile_area .dropdown-item {
  padding: 10px 12px;
  font-weight: 500;
  display: flex;
  font-size: 13px;
  color: #616161;
  align-items: center;
}

.top_header .profile_area .dropdown-item:hover {
  background-color: transparent;
}

.top_header .profile_area .dropdown-item img {
  width: 16px;
  margin-right: 6px;
}

.top_header .profile_area .profile_drop_menu img {
  margin-right: 8px;
  width: 30px;
  height: 30px;
}

.top_header .profile_area .profile_drop_menu .name_placeholder {
  margin-right: 10px;
}

.top_header .profile_area h4 {
  font-weight: 600;
  font-size: 13px;
  color: #0A0A0A;
}

.top_header .profile_area p {
  font-weight: 500;
  font-size: 11px;
  color: #616161;
}

.top_header h4 {
  font-weight: 700;
  font-size: 18px;
  color: #0a0a0a;
}

.sidebar_area.old_sidebar {
  width: 200px;
  transition: all 0.2s, width 0s;
  height: 100vh;
  border-right: 1px solid #f7f7f7;
  background-color: #ffffff;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
}

.logo_sidebar {
  padding: 18px 24px;
}

.logo_sidebar img {
  margin-right: 6px;
  height: 32px;
  cursor: pointer;
}

.logo_sidebar img.text_logo_sidebar {
  cursor: pointer;
  margin-right: 0px;
  height: 26px;
}

.report_page .text_logo_sidebar {
  display: none;
}

.sidebar_menu_area ul.ant-menu {
  border: none !important;
}

.sidebar_menu_area {
  padding: 0 5px;
}

.sidebar_area .ant-menu:not(.ant-menu-sub) {
  margin-top: 35px;
}

.sidebar_area li.ant-menu-item:last-child {
  width: 100%;
}

.sidebar_area .ant-menu-submenu .ant-menu-submenu-title .ant-menu-submenu-arrow {
  position: static;
}

.ant-menu-submenu.ant-menu-submenu-inline .ant-menu-submenu-arrow {
  color: #757575;
}

.ant-menu-submenu.ant-menu-submenu-inline .ant-menu-title-content,
.sidebar_area .ant-menu-item .ant-menu-title-content a {
  text-decoration: none;
  color: #424242;
}

.sidebar_area .ant-menu-inline .ant-menu-item {
  /* height: 40px;
  line-height: normal;
  padding: 10px 0;
  margin: 0; */
}

.sidebar_area .ant-menu-inline .ant-menu-item:not(:last-child),
.sidebar_area .ant-menu-vertical .ant-menu-item:not(:last-child) {
  margin-bottom: 8px;
  width: calc(100% - 0px);
  outline: none;
}

.sidebar_area .ant-menu-submenu-title {
  height: auto;
}

.sidebar_area .ant-menu-submenu-title:focus {
  outline: none;
}

.sidebar_area .ant-menu-submenu.ant-menu-submenu-inline,
.sidebar_area .ant-menu-submenu.ant-menu-submenu-inline {
  height: auto;
  line-height: normal;
}

.sidebar_area .ant-menu-submenu.ant-menu-submenu-vertical .ant-menu-submenu-title,
.sidebar_area .ant-menu-submenu.ant-menu-submenu-inline .ant-menu-submenu-title {
  width: calc(100% - 0px);
}

.sidebar_area .ant-menu-inline.ant-menu-root .ant-menu-item,
.sidebar_area .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  margin: 0;
  height: auto;
}

.sidebar_area .ant-menu-inline .ant-menu-item,
.sidebar_area .ant-menu-vertical .ant-menu-item,
.sidebar_area .ant-menu-inline .ant-menu-submenu-title,
.sidebar_area .ant-menu-vertical .ant-menu-submenu-title {
  margin-inline: 0;
  margin-block: 0;
  height: auto;
}

.ant-menu-submenu-selected svg path {
  fill: #6366f1;
  transition: 0.4s;
}

.ant-menu-submenu-active:hover .ant-menu-submenu-title {
  color: #6366f1;
}

.ant-menu-submenu-active:hover svg path {
  fill: #757575;
  transition: 0.3s;
}

.ant-menu-submenu-selected .ant-menu-submenu-title {
  background-color: #eff0fe;
}

.ant-menu-submenu.ant-menu-submenu-selected .ant-menu-title-content,
.ant-menu-submenu.ant-menu-submenu-selected .ant-menu-submenu-arrow,
.ant-menu-item.ant-menu-item-selected .ant-menu-title-content a,
/* .ant-menu-submenu .ant-menu-sub .ant-menu-item.ant-menu-item-active.ant-menu-item-selected.ant-menu-item-only-child .ant-menu-title-content a, */
.ant-menu-item.ant-menu-submenu-selected .ant-menu-title-content a {
  color: #6366f1;
  font-weight: 600;
  transition: 0s;
}

.ant-menu-item.ant-menu-item-selected .ant-menu-title-content a:hover,
.ant-menu-item.ant-menu-submenu-selected .ant-menu-title-content a:hover {
  color: #6366f1;
  transition: 0s;
}

.sidebar_area .ant-menu-light .ant-menu-item-selected,
.sidebar_area .ant-menu-light>.ant-menu .ant-menu-item-selected {
  background-color: #eff0fe;
  transition: 0s;
}

.sidebar_area .ant-menu-light .ant-menu-item-selected svg path,
.sidebar_area .ant-menu-light>.ant-menu .ant-menu-item-selected svg path {
  fill: #6366f1;
}

.sidebar_area .ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):active,
.sidebar_area .ant-menu-light>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):active {
  background-color: #eff0fe;
}

.sidebar_area .ant-menu-inline .ant-menu-item:not(:last-child),
.sidebar_area .ant-menu-vertical .ant-menu-item:not(:last-child) {
  margin: 0;
}

.sidebar_area .ant-menu .ant-menu-item .ant-menu-item-icon,
.sidebar_area .ant-menu .ant-menu-submenu-title .ant-menu-item-icon {
  vertical-align: -5px;
}

.sidebar_area .ant-menu .ant-menu-submenu-open .ant-menu-submenu-title {
  /* position: relative;
  background-color: #eff0fe; */
}

.sidebar_area .ant-menu .ant-menu-submenu-open .ant-menu-submenu-title svg path {
  /* fill: #6366f1; */
}

.sidebar_area .ant-menu .ant-menu-submenu-active:hover .ant-menu-submenu-title svg path,
.sidebar_area .ant-menu .ant-menu-submenu-open.ant-menu-submenu-active:hover .ant-menu-submenu-title svg path {
  fill: #424242;
}

.sidebar_area .ant-menu .ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-selected .ant-menu-submenu-title::before,
.sidebar_area .ant-menu .ant-menu-submenu-open.ant-menu-submenu-selected .ant-menu-submenu-title::before,
.sidebar_area .ant-menu .ant-menu-item-selected::before {
  position: absolute;
  left: 0;
  top: 5px;
  content: '';
  width: 4px;
  border-radius: 8px;
  z-index: 1;
  height: 80%;
  background-color: #6366F1;
}

.sidebar_area .ant-menu .ant-menu-submenu-open {
  background-color: transparent;
}

.sidebar_area .ant-menu .ant-menu-submenu-open i,
.sidebar_area .ant-menu .ant-menu-submenu-open span {
  /* background-color: transparent;
  color: #6366F1; */
}

.sidebar_area .ant-menu .ant-menu-submenu-open .ant-menu-inline {
  position: relative;
  background: #fff;
}


.sidebar_area .ant-menu .ant-menu-submenu-open .ant-menu-inline li {
  position: relative;
}

.sidebar_area .ant-menu .ant-menu-submenu-open .ant-menu-inline li::before {
  width: 2px;
  height: 40px;
  background-color: #eee;
  position: absolute;
  left: 32px;
  top: 0px;
  content: '';
}

.sidebar_area .ant-menu .ant-menu-submenu-open .ant-menu-inline li::after {
  width: 6px;
  height: 6px;
  background-color: #D9D9D9;
  border-radius: 100px;
  position: absolute;
  left: 30px;
  top: 15px;
  content: '';
  z-index: 1;
  transform: none;
  opacity: 1;
}

.sidebar_area .ant-menu .ant-menu-submenu-open .ant-menu-inline .ant-menu-item-selected {
  background-color: transparent;
}

.sidebar_area .ant-menu .ant-menu-submenu-open .ant-menu-inline .ant-menu-item-selected a {
  font-weight: 600;
}

.sidebar_area .ant-menu .ant-menu-submenu-open .ant-menu-inline .ant-menu-item-selected::after {
  background-color: #6366F1;
}

.sidebar_area .ant-menu .ant-menu-submenu-open .ant-menu-inline .ant-menu-title-content {
  margin-inline-start: 33px;
}

.sidebar_area .ant-menu .ant-menu-submenu-open.ant-menu-submenu-active.ant-menu-submenu-selected:hover .ant-menu-submenu-title {
  background-color: #eff0fe;
}

.sidebar_area .ant-menu .ant-menu-submenu-active.ant-menu-submenu-selected:hover .ant-menu-submenu-title {
  background-color: #eff0fe;
}

.sidebar_area .ant-menu .ant-menu-submenu-open.ant-menu-submenu-active.ant-menu-submenu-selected:hover .ant-menu-submenu-title svg path {
  fill: #6366F1;
}

.sidebar_area .ant-menu .ant-menu-submenu-active.ant-menu-submenu-selected:hover .ant-menu-submenu-title svg path {
  fill: #6366F1;
}

.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:active,
.ant-menu-light>.ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-title:active {
  background-color: #eff0fe;
}

.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-selected .ant-menu.ant-menu-sub.ant-menu-inline .ant-menu-item.ant-menu-item-only-child .ant-menu-title-content a {
  font-weight: 400;
}

.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-selected .ant-menu.ant-menu-sub.ant-menu-inline .ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child .ant-menu-title-content a {
  font-weight: 600;
}

.sidebar_area .ant-menu .ant-menu-submenu-title,
.sidebar_area .ant-menu .ant-menu-item {
  padding: 0 24px !important;
}

.ant-menu-submenu-popup .ant-menu-vertical>.ant-menu-item,
.ant-menu-submenu-popup .ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title {
  height: 32px;
  line-height: 32px;
}