.search_width {
    width: 292px;
    height: 40px;
}

.custom_search_bar .form-control {
    border: 1px solid #e0e0e0;
    font-weight: 400;
    font-size: 14px;
    border-radius: 8px;
    padding: 0 4px;
}

.custom_search_bar .form-control:focus {
    outline: none;
}

.custom_search_bar .form-control::placeholder {
    color: #bdbdbd;
    font-weight: 400;
}

.custom_search_bar .input-group-text {
    border: 1px solid #e0e0e0;
    background-color: #fff;
    border-radius: 8px;
    padding: 10px 8px 10px 12px;
}

.custom_search_bar .input-group-text i {
    color: #9E9E9E;
    font-size: 14px;
}

.user_comment_text path {
    fill: #bdbdbd;
}

.user_comment_text:hover path {
    fill: #757575;
}