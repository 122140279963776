.ord_table_design {
    padding: 16px 16px 17px;
    box-sizing: border-box;
    height: 100%;
}

.before_create_report_design {
    position: relative;
    height: calc(100vh - 180px);
    overflow-y: hidden;
}

/* .blue_strip_design .before_create_report_design {
    position: relative;
    height: calc(100vh - 222px);
    overflow-y: hidden;
} */

.before_create_report_design.new_ant_table_design_history {
    position: relative;
    height: calc(100vh - 204px);
    overflow-y: hidden;
}

/* .blue_strip_design .before_create_report_design.new_ant_table_design_history {
    position: relative;
    overflow-y: hidden;
    height: calc(100vh - 246px);
} */

.table_record_texgt {
    color: #757575;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.07px;
    display: block;
    text-align: right;
    padding: 8px 2px 0 0;
}

.before_create_report_design .ant-table-body {
    max-height: calc(100vh - 216px) !important;
}

/* .blue_strip_design .before_create_report_design .ant-table-body {
    max-height: calc(100vh - 257px) !important;
} */

.before_create_report_design.new_ant_table_design_history .ant-table-body {
    max-height: calc(100vh - 238px) !important;
}

.before_create_report_design .temp_table_ord .ant-table-body {
    max-height: calc(100vh - 544px) !important;
    height: 100vh;
}

.ord_table_design .edit_campaign_tabs_design ul {
    display: flex;
    margin: 0;
    padding: 0;
}

.ord_table_design .edit_campaign_tabs_design ul li {
    list-style-type: none;
}

.ord_table_design .edit_campaign_tabs_design .nav-link.btn.active {
    padding: 0 0 8px;
}


.ord_table_design .edit_campaign_tabs_design li {
    margin-right: 24px;
}

.ord_table_design .edit_campaign_tabs_design .nav-link {
    display: flex;
    align-items: center;
    padding: 0 0 8px;
}

.ord_table_design .edit_campaign_tabs_design .nav-link svg {
    margin-right: 4px;
    transition: 1s;
}

.ord_table_design .edit_campaign_tabs_design .nav-link.btn:hover svg.history_icon path {
    stroke: #000000;
    transition: 0.3s;
    fill: transparent;
}

.ord_table_design .edit_campaign_tabs_design .nav-link.btn:hover svg path {
    transition: 0.3s;
    fill: #000000;
}

.ord_table_design .edit_campaign_tabs_design .nav-link.btn.active svg path {
    fill: #000000;
}

.ord_table_design .edit_campaign_tabs_design .nav-link.btn.active svg.history_icon path {
    stroke: #000000;
    fill: transparent;
}


.new_ant_table_design .ant-table-expanded-row.ant-table-expanded-row-level-1 .ant-table-cell {
    background-color: #fff;
    color: #424242;
    font-weight: 500;
}

.new_ant_table_design .ant-table-expanded-row.ant-table-expanded-row-level-1 .ant-table-cell.ant-table-cell-row-hover {
    background-color: #fff;
}

.new_ant_table_design .ant-table-expanded-row.ant-table-expanded-row-level-1 .ant-table {
    border-radius: 0;
    margin-inline: 23.3% -16px !important;
    border-left: 1px solid #f0f0f0;
}

.new_ant_table_design.new_ant_table_design_history .ant-table-expanded-row.ant-table-expanded-row-level-1 .ant-table {
    border: 1px solid #f0f0f0;
    border-radius: 8px !important;
}

.new_ant_table_design.new_ant_table_design_history .ant-table-wrapper .ant-table-tbody>tr>td>.diff_table_with_diff_row .ant-table-wrapper .ant-table-tbody>tr:last-child td {
    border-top: 1px solid #bdbdbd;
    color: #0a0a0a;
    font-weight: 600;
    background-color: #f9f9f9 !important;
}

.new_ant_table_design.new_ant_table_design_history .ant-table-wrapper .ant-table-tbody>tr>td>.diff_table_with_diff_row .ant-table-wrapper .ant-table-tbody>tr:last-child td span.empty-cell-class {
    color: #0a0a0a;
    font-weight: 600;
}


.new_ant_table_design .ant-table-thead>tr>th.ant-table-cell::before {
    display: none;
}

.new_ant_table_design .new_two_table .ant-table-thead>tr>th.ant-table-cell,
.new_ant_table_design .ant-table-thead>tr>th.ant-table-cell {
    color: #757575;
    font-size: 11px;
    font-weight: 600;
    line-height: 18px;
    text-transform: uppercase;
    padding: 7.5px 8px;
    white-space: nowrap;
    /* position: sticky !important;
    top: 0;
    z-index: 9; */
}

.new_ant_table_design .ant-table-tbody>tr.ant-table-row-level-0>td {
    color: #0A0A0A;
    /* background-color: #EFF0FE; */
    padding: 5px 8px;
    height: 44px;
    line-height: normal;
    font-weight: 600;
    font-size: 13px;
}

.new_ant_table_design .ant-table-tbody>tr.ant-table-row-level-0>td.ant-table-row-expand-icon-cell {
    padding: 0;
}

.new_ant_table_design .ant-table-tbody>tr.ant-table-row-level-0>td.ant-table-cell-row-hover {
    background-color: transparent;
}

.new_ant_table_design .ant-table-tbody>tr.ant-table-row-level-0 {
    background-color: #EFF0FE;
}

.new_ant_table_design .ant-checkbox-wrapper .ant-checkbox:hover .ant-checkbox-inner {
    border: 1px solid #6366F1;
}

.new_ant_table_design .ant-checkbox-wrapper.ant-checkbox-wrapper-checked:hover .ant-checkbox-inner {
    background-color: #6366F1 !important;
}

.new_ant_table_design_history .ant-table-wrapper .ant-table-tbody>tr.ant-table-row:hover>td,
.new_ant_table_design_history .ant-table-wrapper .ant-table-tbody>tr>th.ant-table-cell-row-hover>td.ant-table-cell-row-hover {
    background: #EFF0FE;
}

.new_ant_table_design_history .ant-table-wrapper .ant-table-tbody>tr>td>.diff_table_with_diff_row .ant-table-wrapper .ant-table-tbody>tr.ant-table-row:hover>td,
.new_ant_table_design_history .ant-table-wrapper .ant-table-tbody>tr>td>.diff_table_with_diff_row .ant-table-wrapper .ant-table-tbody>tr>th.ant-table-cell-row-hover>td.ant-table-cell-row-hover {
    background-color: #fff;
}

.new_ant_table_design .ant-checkbox-wrapper .ant-checkbox.ant-checkbox-checked::after,
.new_ant_table_design .ant-checkbox-wrapper.ant-checkbox-wrapper-checked:hover .ant-checkbox::after {
    /* background-color: #6366F1 !important; */
    border-color: #6366F1 !important;
}

.new_ant_table_design .ant-checkbox-wrapper .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner,
.new_ant_table_design .ant-checkbox-wrapper .ant-checkbox.ant-checkbox-indeterminate .ant-checkbox-inner:after {
    background-color: #6366F1;
    border: 1px solid #6366F1;
}

.new_ant_table_design .ant-table-tbody>tr.ant-table-row-level-0.ant-table-row-selected>td .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #6366F1;
    border: 1px solid #6366F1;
    width: 16px;
    height: 16px;
}

.new_ant_table_design .ant-table-tbody>tr.ant-table-row-level-0.ant-table-row-selected>td {
    background-color: #D0D1FB;
}

.new_ant_table_design .ant-table-tbody>td.ant-table-cell-row-hover {
    background-color: transparent;
}

/* .new_ant_table_design .ant-table-container .ant-table-content table tbody.ant-table-tbody>tr.ant-table-expanded-row-level-1>td .ant-table-container>.ant-table-content {
    border-left: 1px solid #f0f0f0;
} */

.new_ant_table_design .ant-table-container .ant-table-content table tbody.ant-table-tbody>tr.ant-table-expanded-row-level-1 .ant-table-row-level-0>td {
    background-color: #fff;
}


.sumbit_btn_design .btn {
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    width: 128px;
    /* height: 40px; */
    /* margin-right: 20px; */
    line-height: 0px;
    display: block;
    padding: 8px 12px;
    color: #ffffff;
    text-decoration: none;
    border: none;
}

.table_input_design.form-control {
    height: 34px;
    padding: 6px 10px;
    border-color: #E0E0E0;
    color: #424242;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    /* width: 260px; */
}

.table_input_design:hover {
    border: 1px solid #BDBDBD;
}

.table_input_design.form-control:disabled {
    background-color: transparent !important;
    color: #424242 !important;
}

.new_ant_table_design .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner:after {
    background-image: url('../assets/images/ord_checkbox_arrow.svg');
    border: none;
    width: 15px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    top: 50%;
    transform: translateY(-50%);
    left: -1px;
}

.new_ant_table_design .ant-checkbox-wrapper .ant-checkbox.ant-checkbox-indeterminate .ant-checkbox-inner:after {
    background-image: none;
    width: 8px;
    left: 3px;
}

.confirm_screen_design {
    width: 100%;
    height: auto;
    border-radius: 8px;
    background-color: rgba(255, 244, 226, 0.40);
    margin: 17px 0 0;
    position: relative;
    /* overflow: hidden; */
}

.confirm_screen_design.uae_alert_design .alert_design {
    padding: 6.3px 0 6.3px 16px;
}

.alert_design {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #FFE0B2;
    overflow: hidden;
    border-radius: 8px 8px 0 0;
}

.alert_design .alert-dismissible {
    margin: 0;
    padding: 0;
    width: 100%;
}

.alert_design .alert-heading.h4 {
    color: #0A0A0A;
    font-size: 13px;
    font-weight: 600;
    line-height: 150%;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}


.alert_design .alert-heading.h4 small {
    margin-left: 12px;
    transform: translateY(0px);
    color: #0661D8;
    cursor: pointer;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.13px;
}

.alert_design .alert-heading.h4 small svg {
    transform: translateY(-1px);
    margin-right: 4px;
}

.alert_design .alert_icon {
    background-color: #FF9F29;
    display: flex;
    height: 34px;
    padding: 0 10px;
    margin-right: 8px;
    align-items: center;
    justify-content: center;
}

.alert_design .alert-dismissible .btn-close {
    background-image: url(../assets/images/alert_close_icon.svg);
    padding: 0;
    width: 24px;
    height: 24px;
    top: -2px;
    opacity: 1;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    right: 10px;
}

.alert_form_area_design {
    padding: 16px 16px 12px;
    display: inline-flex;
    flex: 1 0 0;
    flex-wrap: wrap;
    width: 64%;
}

.input_width_div {
    width: 270px;
    margin-right: 8px;
    margin-bottom: 12px;
}

.input_width_div .react-datepicker-wrapper {
    width: 100%;
}

.input_width_div label {
    color: #616161;
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    line-height: 150%;
    padding-bottom: 4px;
}

.input_width_div .form-control {
    border-radius: 8px;
    border: 1px solid #E0E0E0;
    background: #FFF;
    height: 36px;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    padding: 8px 12px;
}

.input_width_div.input_width_div_date_picker .form-control {
    padding: 8px 12px 8px 35px;
}

.input_width_div.input_width_div_date_picker .date_icon_svg svg.date_icon_cls {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.input_width_div .form-control::placeholder {
    color: #BDBDBD;
}

.input_width_div input[type='date']::-webkit-datetime-edit {
    color: #BDBDBD;
}

.custom_file_uploader_design {
    position: relative;
    height: 36px;
    padding: 8px 12px;
    width: 100%;
    border: 1px dashed #E0E0E0;
    background: #FFF;
    border-radius: 8px;
}

.custom_file_uploader_design span.before_upload_file {
    color: #616161;
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    display: -webkit-box;
    max-width: 175px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.custom_file_uploader_design span {
    color: #212121;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    display: -webkit-box;
    max-width: 175px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.custom_file_uploader_design .custom_upld_btn input[type="file"] {
    position: absolute;
    left: 0;
    top: -3px;
    height: 34px;
    width: 100%;
    opacity: 0;
}

.custom_file_uploader_design .custom_upld_btn {
    right: 2px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.custom_file_uploader_design .custom_upld_btn .btn {
    position: relative;
    overflow: hidden;
    height: 32px;
    padding: 8px 16px;
    border-radius: 6px;
    border: 1px solid #EEE;
    background: #F5F5F5;
    display: flex;
    justify-content: center;
    color: #424242;
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
    transition: 0.3s;
}

.custom_file_uploader_design .custom_upld_btn .btn:hover {
    background-color: #e0e0e0;
    transition: 0.3s;
}

.alert_form_line {
    margin: 0;
    opacity: 1;
    border-color: #E0E0E0;
}

.alert_design_two_btn {
    padding: 16px;
}

/* extra by tej */


.new_ant_table_design.new_ant_table_design_history .ant-table-tbody>tr[data-row-key="22"],
.new_ant_table_design.new_ant_table_design_history .ant-table-tbody>tr[data-row-key="11"] {
    background-color: #fff;
}

.new_ant_table_design.new_ant_table_design_history .ant-table-row.ant-table-row-level-0 .ant-table-row-expand-icon {
    background-image: url(../assets/images/nested_arrow_icon.svg);
    background-color: transparent;
    height: 3px;
    width: 3px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 10px 20px;
    margin: 0;
    transition: 0.1s;
    box-sizing: content-box;
    border: none;
}

.new_ant_table_design.new_ant_table_design_history .ant-table-row.ant-table-row-level-0 .ant-table-row-expand-icon.ant-table-row-expand-icon-expanded {
    height: 3px;
    width: 3px;
    transition: 0.1s;
    transform: rotate(90deg);
}

.new_ant_table_design.new_ant_table_design_history .ant-table-row.ant-table-row-level-0 .ant-table-row-expand-icon:focus,
.new_ant_table_design.new_ant_table_design_history .ant-table-row.ant-table-row-level-0 .ant-table-row-expand-icon:active,
.new_ant_table_design.new_ant_table_design_history .ant-table-row.ant-table-row-level-0 .ant-table-row-expand-icon:hover {
    border-color: transparent;
    box-shadow: none;
}

.new_ant_table_design.new_ant_table_design_history .ant-table-row.ant-table-row-level-0 .ant-table-row-expand-icon::after,
.new_ant_table_design.new_ant_table_design_history .ant-table-row.ant-table-row-level-0 .ant-table-row-expand-icon::before {
    opacity: 0;
}

.new_ant_table_design.new_ant_table_design_history .ant-table-row.ant-table-row-level-1 {
    background-color: #F2F4F7;
}

.new_ant_table_design.new_ant_table_design_history .ant-table-row.ant-table-row-level-2>td>button,
.new_ant_table_design.new_ant_table_design_history .ant-table-row.ant-table-row-level-1>td>button {
    display: none;
}

.new_ant_table_design.new_ant_table_design_history .ant-table-expanded-row.ant-table-expanded-row-level-1 .ant-table {
    border-radius: 0;
    margin-inline: 3% -3px !important;
    margin: 10px;
    overflow: hidden;
}

.new_ant_table_design.new_ant_table_design_history .ant-table-expanded-row.ant-table-expanded-row-level-1>td[colspan="7"],
.new_ant_table_design.new_ant_table_design_history .ant-table-expanded-row.ant-table-expanded-row-level-1>td[colspan="8"],
.new_ant_table_design.new_ant_table_design_history .ant-table-expanded-row.ant-table-expanded-row-level-1>td[colspan="9"],
.new_ant_table_design.new_ant_table_design_history .ant-table-expanded-row.ant-table-expanded-row-level-1>td[colspan="10"],
.new_ant_table_design.new_ant_table_design_history .ant-table-expanded-row.ant-table-expanded-row-level-1>td[colspan="12"],
.new_ant_table_design.new_ant_table_design_history .ant-table-expanded-row.ant-table-expanded-row-level-1>td[colspan="13"],
.new_ant_table_design.new_ant_table_design_history .ant-table-expanded-row.ant-table-expanded-row-level-1>td[colspan="11"] {
    padding: 0 16px;
}

.new_ant_table_design.new_ant_table_design_history .new_two_table .ant-table-tbody>tr>td.ant-table-cell {
    color: #424242;
    font-size: 13px;
    font-weight: 500;
}

.new_ant_table_design.new_ant_table_design_history .new_two_table .ant-table-tbody>tr>td.ant-table-cell span.empty-cell-class {
    color: #0A0A0A;
    font-weight: 600;
}

.new_ant_table_design .ant-table-thead>tr>th.ant-table-cell.ant-table-selection-column,
.new_ant_table_design .ant-table-tbody>tr>td.ant-table-cell.ant-table-selection-column {
    padding: 0 0 0 16px;
}

.new_ant_table_design.new_ant_table_design_history .diff_table_with_diff_row .ant-table-tbody>tr.parent-has-empty-cell>td {
    background-color: #F2F4F7 !important;
}

.new_ant_table_design.new_ant_table_design_history .diff_table_with_diff_row .ant-table-tbody>tr:first-child td {
    color: #0A0A0A !important;
}

.ord_date_selction input {
    cursor: pointer;
}

.ord_date_range_picker .daterangepicker {
    right: 40px !important;
}

.ord_table_design .custom_search_bar .input-group-text {
    padding: 0 7px 0px 8px;
}

.ord_table_design .custom_search_bar .custom_date_width_css {
    height: 36px !important;
}

.new_ant_table_design .temp_table_ord .ant-table-expanded-row.ant-table-expanded-row-level-1 .ant-table {
    margin-inline: 22.5% -16px !important;
}

/* .before_create_report_design.new_ant_table_design {
    position: relative;
    height: auto;
    overflow-y: scroll;
} */

span.email_id_text_clr {
    color: #424242 !important;
    font-weight: 500;
}

.temp-ord-table .before_create_report_design.after_submit_table_design {
    margin-top: 10px;
    height: auto;
    max-height: calc(100vh - 464px);
}

.parent2-has-empty-cell>td {
    color: #0A0A0A !important;
    font-weight: 600 !important;
}

.ord_table_design .css-t3ipsp-control,
.ord_table_design .css-16xfy0z-control,
.ord_table_design .css-13cymwt-control {
    height: 36px !important;
    min-height: 36px !important;
}

.ord_table_design .new_drop_design_focus .absolute_icons {
    top: 9px;
}

/* .new_ant_table_design .ant-table-wrapper .ant-table-tbody>tr>th,
.new_ant_table_design .ant-table-wrapper .ant-table-tbody>tr>td {
    transition: background 0.2s, border-color 0.2s;
    border-bottom: 1px solid #ffffff;
} */

.ord_table_design .new_drop_design_focus .select-custom .css-hlgwow,
.ord_table_design .new_drop_design_focus .select-custom .css-1fdsijx-ValueContainer {
    padding: 2px 32px;
}

.border_radius_8 {
    border: 0.3px solid red !important;
    border-radius: 8px !important;
}

.after_submit_table_total_design .ant-table-tbody>tr.ant-table-row-level-0>td.ant-table-cell-row-hover,
.after_submit_table_total_design .ant-table-tbody>tr.ant-table-row-level-0>td {
    background-color: #fff;
}

.input_width_div_date_picker .react-datepicker__navigation--next,
.input_width_div_date_picker .react-datepicker__navigation--previous {
    top: 9px;
}

.ord_table_design .custom_search_bar .form-control {
    cursor: default;
}

.before_create_report_design .ant-table-wrapper .ant-table-ping-right .ant-table-container::after,
.before_create_report_design .ant-table-wrapper .ant-table-ping-left .ant-table-container::before {
    box-shadow: none !important;
}

/* total left line */
/* .after_submit_table_total_design .ant-table-row.ant-table-row-level-0>td:nth-child(2) {
    border-left: 1px solid #f0f0f0;
} */

.id_different_colour {
    color: #2C6AB7 !important;
}

.withvat_colour {
    color: #424242 !important;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
}

.new_ant_table_design_history .ant-table-body>table>.ant-table-tbody>tr.ant-table-row.ant-table-row-level-0>td {
    border-bottom: 2px solid #fff;
}

.uae_screen .uae_no_data_screen {
    height: calc(100vh - (461px));
    height: -webkit-calc(100vh - (461px));
    position: relative;
}


.new_ant_table_design_uae .ant-table-thead>tr>th.ant-table-cell,
.new_ant_table_design_uae .ant-table-thead>tr>th.ant-table-cell {
    color: #757575;
    font-size: 11px;
    font-weight: 600;
    line-height: 18px;
    text-transform: uppercase;
    padding: 7.5px 22px;
    white-space: nowrap;
}

.new_ant_table_design_uae .ant-table-tbody>tr.ant-table-row-level-0>td {
    color: #424242;
    padding: 5px 22px;
    height: 44px;
    line-height: normal;
    font-weight: 500;
    font-size: 13px;
}

.new_ant_table_design_uae .ant-table-tbody>tr.ant-table-row-level-0>td span {
    color: #0a0a0a;
    font-weight: 600;
}

.new_ant_table_design_uae .ant-table-thead>tr>th.ant-table-cell::before {
    display: none;
}

.new_ant_table_design_uae .ant-table-tbody>tr.ant-table-row-level-0>td.ant-table-cell-row-hover {
    background-color: transparent;
}

.before_create_report_design.new_ant_table_design_uae {
    height: calc(100vh - 204px);
}

.unhide_UAE_form .before_create_report_design.new_ant_table_design_uae {
    height: calc(100vh - 515px);
}

.before_create_report_design.new_ant_table_design_uae .ant-table-body {
    max-height: calc(100vh - 239px) !important;
}

.unhide_UAE_form .before_create_report_design.new_ant_table_design_uae .ant-table-body {
    max-height: calc(100vh - 550px) !important;
}

.table_alert_box_line {
    opacity: 1;
    border-color: #e0e0e0;
}

.date_icon_svg .ant-picker-range-separator {
    padding: 0;
    transform: translate(-10px, -4px);
}

.date_icon_svg .ant-picker-input input {
    font-size: 14px;
    font-weight: 400;
    color: #212529;
    transform: translateY(-1px);
}

.date_icon_svg.date_icon_svg_vertical_alignment .ant-picker-input input {
    transform: translateY(1px);
}

.date_icon_svg .ant-picker-active-bar {
    display: none;
}


.confirm_screen_design .ant-picker-focused {
    box-shadow: none;
    border-color: #6366f1 !important;
    outline: 4px solid #eff0fe;
    outline-offset: initial;
}

.red_alert_text {
    color: #FC1B13;
}

.hide_UAE_form .confirm_screen_design.uae_alert_design {
    display: none;
}

.hide_UAE_form.uae_screen .uae_no_data_screen {
    height: calc(100vh - (165px));
    height: -webkit-calc(100vh - (165px));
    position: relative;
}

/* .ord_date_range_picker .ant-picker-cell-range-end .ant-picker-cell-inner,
.ord_date_range_picker .ant-picker-cell-range-start .ant-picker-cell-inner {
    background-color: #6366f1 !important;
}

.ord_date_range_picker .ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: #6366f1 !important;
}

.ord_date_range_picker .ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: #6366f1 !important;
} */

.ord_date_range_picker .ant-picker-dropdown .ant-picker-cell-disabled::before {
    background: transparent;
}

.ord_date_range_picker .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after,
.ord_date_range_picker .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after,
.ord_date_range_picker .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover::after {
    border: none !important;
    background-color: #e6f4ff;
}


.ord_date_range_picker .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ord_date_range_picker .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ord_date_range_picker .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: #fff;
    background: #6366f1;
}

.ord_date_range_picker .ant-picker-dropdown .ant-picker-cell .ant-picker-cell-inner {
    font-size: 12px;
}


.submit_act_btn {
    margin: 0 4px 0 0;
    margin-left: 0 !important;
}