.new_side_bar .ant-layout-sider {
    background-color: #fff;
    height: 100vh;
}

.new_side_bar .ant-menu-submenu-title {
    margin: 0;
}

/* ////////no page found css starts ///////// */
.not_found_page h2 {
    color: #212121;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    padding: 28px 0 4px;
}

.not_found_page p {
    color: #BDBDBD;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    padding-bottom: 28px;
}

/* ////////no page found css ends ///////// */

/* start css for blue strip design */
/* .blue_strip_design .blue_alert_text {
    background-color: #2C6AB7;
    height: 44px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0px 12px;
    display: flex;
    align-items: center;
}

.blue_strip_design .blue_alert_text p {
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.42px;
    display: flex;
    align-items: center;
}

.blue_strip_design .blue_alert_text p span {
    font-weight: 700;
}

.blue_strip_design .blue_alert_text p svg {
    margin-right: 8px;
}

.blue_strip_design .top_header {
    top: 44px;
}

.blue_strip_design .sidebar_area {
    top: 44px;
}

.blue_strip_design .main_contain_area {
    margin-top: 125px;
    height: calc(100vh - (138px));
    height: -webkit-calc(100vh - (138px));
}

.blue_strip_design .blue_alert_close_btn {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    margin: 0;
    cursor: pointer;
} */