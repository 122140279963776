.edit_campaign_tabs_design .nav-link.btn.active {
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom: 2px solid #6366F1;
    padding: 0;
    padding: 0 31px 10px;
    font-weight: 700;
    color: #212121;
}

.edit_campaign_tabs_design .international_box_design .nav-link.btn.active {
    color: #6366F1;
    padding-bottom: 4px;
}

.edit_campaign_tabs_design .international_box_design li.device_custom {
    color: #BDBDBD;
}

.edit_campaign_tabs_design .nav-link {
    border-radius: 0;
    padding: 0;
    padding: 0 31px 10px;
    font-weight: 500;
    color: #9E9E9E;
    font-size: 14px;
}

.edit_campaign_tabs_design .international_box_design .nav-link {
    padding-bottom: 4px;
}

.edit_campaign_tabs_design .nav-link.active {
    border-right-color: transparent;
    border-left-color: transparent;
    border-top-color: transparent;
}

.edit_campaign_tabs_design .step_area {
    padding: 20px 21px 0;
    margin-bottom: 22px;
}

.edit_campaign_tabs_design .nav-link:hover {
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom: 2px solid transparent;
    color: #0A0A0A;
}

.step_input_fields .css-16xfy0z-control {
    height: 40px;
    border-radius: 8px;
}

.step_input_fields .css-3w2yfm-ValueContainer {
    padding: 2px 2px;
}

.fixed_width_im {
    width: 165px;
}

.select-custom .css-1fdsijx-ValueContainer {
    padding: 2px 12px;
}

.select-custom .css-qbdosj-Input {
    margin: 0;
    padding: 0;
}

.select-custom .css-1jqq78o-placeholder {
    margin: 0;
}

.only_view_design_modal.centered_modal_small_large_view .modal-dialog {
    height: 100vh;
    margin: 0 auto;
}

.only_view_design_modal .modal-body {
    height: auto;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    /* margin-top: 10px; */
}

.only_view_design_modal .video-react-button {
    position: absolute !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
}

.only_view_design_modal .custom_carousel_width {
    width: 800px;
}

.only_view_design_modal .carousel-inner {
    display: flex;
    align-items: center;
}

.delete_icon_svg {
    cursor: pointer;
}

.eye_icon_svg {
    cursor: pointer;
    margin-right: 10px;
}

.delete_icon_svg:hover path {
    fill: #f83a39;
}

.eye_icon_svg:hover path {
    fill: #424242;
}

iframe.s_300_250 {
    width: 300px;
    height: 260px;
}

iframe.s_320_480 {
    width: 320px;
    height: 490px;
}

iframe.s_480_320 {
    width: 480px;
    height: 330px;
}

iframe.s_728_90 {
    width: 728px;
    height: 100px;
}

iframe.s_320_50 {
    width: 320px;
    height: 60px;
}

iframe.s_300_50 {
    width: 300px;
    height: 60px;
}

/* .only_view_design_modal #frame_prop {
    width: 100%;
    height: auto;
    min-height: 600px;
    text-align: center;
    max-width: 729px;
    min-width: 320px;
    width: -webkit-fill-available;
} */

.custom_container_form .ant-select-open .ant-select-selector {
    border-radius: 8px;
    border-color: #6366f1 !important;
    transition: 0s;
    border-width: 1px !important;
    outline: 4px solid #eff0fe !important;
}

.ant-tree-select-dropdown {
    padding: 12px;
}

.ant-select-tree-switcher.ant-select-tree-switcher-noop {
    width: 0px !important;
}

.under_line_drop {
    border-bottom: 1px solid #eee;
    padding: 0px 0 6px;
    margin: 0px 0 6px;
    width: 100%;
    display: flex;
    align-items: center;
}

.form-check-input:checked {
    background-color: #6366f1;
    border-color: #6366f1;
}

.ant-tree-select-dropdown .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
    background-color: #6366f1;
    border-color: #6366f1;
}

.ant-tree-select-dropdown .ant-select-tree-checkbox-wrapper-checked:not(.ant-select-tree-checkbox-wrapper-disabled):hover .ant-select-tree-checkbox-inner,
.ant-tree-select-dropdown .ant-select-tree-checkbox-checked:not(.ant-select-tree-checkbox-disabled):hover .ant-select-tree-checkbox-inner {
    background-color: #6366f1;
    border-color: #6366f1;
}

.ant-tree-select-dropdown .ant-select-tree-checkbox-wrapper:not(.ant-select-tree-checkbox-wrapper-disabled):hover .ant-select-tree-checkbox-inner,
.ant-tree-select-dropdown .ant-select-tree-checkbox:not(.ant-select-tree-checkbox-disabled):hover .ant-select-tree-checkbox-inner {
    border-color: #6366f1;
}

.ant-tree-select-dropdown .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner:after {
    background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e) !important;
    width: 14px;
    height: 14px;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0;
    border: none;
    transition: 0s;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
}

.ant-select-tree-checkbox.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
    background-color: #6366f1;
}

.ant-tree-select-dropdown .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner:after {
    background-color: #ffffff;
    height: 2px;
}

.form-check-input:focus {
    box-shadow: none;
    border-color: #6366f1;
}

.ant-switch {
    background-color: #eff0fe;
}

.ant-switch .ant-switch-handle::before {
    box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.1);
}

/* .ant-switch:hover:not(.ant-switch-disabled) {
    background-color: #a1a3f7;
} */

.ant-switch:hover:not(.ant-switch-disabled) {
    background: #a1a3f7;
}