.custom_container_form {
  width: 1204px;
}

.custom_col_space_label {
  width: 287px;
}

.divider_line {
  border-bottom: 0.8px solid #eeeeee;
  width: 1182px;
}

.divider_line_two {
  border-bottom: 0.8px solid #eeeeee;
  width: 896px;
}

.divider_line.minus_line_number {
  margin-left: -286px;
}

.w-896 {
  width: 896px;
}

.w-148 {
  width: 148px;
}

.w-425 {
  width: 425px;
}

.w-173 {
  width: 173px;
}

.w-220 {
  width: 220px;
}

.w-656 {
  width: 656px;
}

.w-440 {
  width: 440px;
}

.w-280 {
  width: 280px;
}

.w-237 {
  width: 280px;
}

.w-340 {
  width: 340px;
}

.w-356 {
  width: 356px;
}

.w-153 {
  width: 153px;
}

.w-640 {
  width: 640px;
}

.w-180 {
  width: 180px;
}

.w-1328 {
  width: 1328px;
}

.w-206 {
  width: 206px;
}

.w-208 {
  width: 208px;
}

.w-240 {
  width: 240px;
}

.w-218 {
  width: 218px;
}

.w-114 {
  width: 114px;
}

.w-293 {
  width: 293px;
}

.w-669 {
  width: 669px;
}

.w-727 {
  width: 727px;
}

.w-216 {
  width: 216px;
}

.w-76 {
  width: 76px;
}

.w-175 {
  width: 175px;
}

.w-212 {
  width: 212px;
}

.w-102 {
  width: 102px;
}

.w-444 {
  width: 444px;
}

.w-547 {
  width: 547px;
}

.w-539 {
  width: 539px !important;
}

.w-textarea {
  width: 897px;
}

.w-152 {
  width: 152px;
}

.w-270 {
  width: 270px;
}

.w-222 {
  width: 222px;
}

.h-180 {
  height: 180px;
}

.h-291 {
  height: 291px;
}

.h-280 {
  height: 280px;
}

.h-28 {
  height: 28px !important;
}

.h-380 {
  height: 380px;
}

.h-480 {
  height: 480px;
}

.h-127 {
  height: 127px;
}

.h-571 {
  height: 571px;
}

.h-272 {
  height: 272px;
}

.h-300 {
  height: 300px;
}

.h-275 {
  height: 275px;
}

.h-40 {
  height: 40px;
}

.h-40-imp {
  height: 40px !important;
}

.filter-button .h-40 {
  height: 40px !important;
}

.h-312 {
  height: 312px;
}

.h-114 {
  height: 114px !important;
}

.h-470 {
  height: 470px;
}

.me-10 {
  margin-right: 10px;
}

.h-470 {
  height: 470px;
}

.h-150 {
  height: auto;
  min-height: 150px !important;
}

.me-16 {
  margin-right: 16px;
}

.me-15 {
  margin-right: 15px;
}

.m-8 {
  margin: 8px;
}

.me-24 {
  margin-right: 24px;
}

.me-8 {
  margin-right: 8px;
}

.me-85 {
  margin-right: 85px;
}

.me-12 {
  margin-right: 12px;
}

.ms-12 {
  margin-left: 12px;
}

.mtt-1 {
  margin-top: 1px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-14 {
  margin-top: 14px;
}

.mb-6 {
  margin-bottom: 6px;
}

.me-6 {
  margin-right: 6px;
}

.fs-13 {
  font-size: 13px;
}

.p-12 {
  padding: 12px;
}

.pe-8 {
  padding-right: 8px;
}

.mt-12 {
  margin-top: 12px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-8 {
  margin-top: 8px;
}

.fn-12 {
  font-size: 12px;
}

.fn-13 {
  font-size: 13px !important;
}

.z_index_1 {
  z-index: 1;
}

.transform-y-m-1 {
  transform: translateY(-1px);
}

.transform-y-m-2 {
  transform: translateY(-2px);
}

.w-124 {
  width: 124px;
}

.mb_8 {
  margin-bottom: 8px;
}