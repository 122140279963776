.table_area_spacing {
    padding: 20px 23px;
}

.table_border_radius {
    margin-top: 18px;
    border: 1px solid #EEEEEE;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
}


.table_border_radius .ant-select {
    height: auto;
    min-height: auto;
}

.table_border_radius .ant-select .ant-select-selector {
    height: 26px;
    width: 110px;
    min-height: auto;
    font-size: 11px;
    border: 1px solid #ededed;
}

.table_border_radius .ant-select-item.ant-select-item-option,
.table_border_radius .ant-select-item-option-content {
    font-size: 11px;
}

.addClassBody .ant-select-item-option-content {
    font-size: 11px;
}

.table_border_radius .ant-select .ant-select-selection-item {
    background-color: transparent;
    border: none;
    font-size: 12px;
    font-weight: 500;
    color: #616161;
    line-height: 24px;
}

.custom_data_table {
    vertical-align: middle;
    margin-bottom: 0px;
    border-radius: 8px 8px 0 0;
    border-color: #EEEEEE;
}

.custom_data_table thead.ant-table-thead,
.custom_data_table thead tr th,
.custom_data_table thead {
    background-color: #F5F5F5;
}

.custom_data_table thead.ant-table-thead .ant-table-cell {
    background-color: transparent;
}

.custom_data_table .ant-table-thead .ant-table-cell::before {
    display: none;
}

.custom_data_table .ant-table-thead .ant-table-cell,
.custom_data_table thead tr th {
    color: #757575;
    padding: 10px 30px 7px;
    white-space: nowrap;
    position: relative;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 11px;
    cursor: pointer;
    height: 34px;
}

/* .custom_data_table thead tr th:first-child.unsort::after,
.custom_data_table thead tr th:first-child.sort-asc::after,
.custom_data_table thead tr th:first-child.sort-desc::after {
    display: none;
} */

.custom_data_table .ant-table-wrapper thead.ant-table-thead tr th .ant-table-column-sorter::after {
    position: inherit !important;
}


.ant-table-column-sorters {
    justify-content: start !important;
}

.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
    font-size: 9px !important;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
    color: #757575 !important;
}

.ant-table-column-sorter {
    color: #bdbdbd !important;
}

.ant-table-column-sorters:after {
    content: none !important;
}

.ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: transparent !important;
}

.ant-table-column-sorters:hover .ant-table-column-sorter {
    color: #bdbdbd !important;
}

.ant-table-column-title {
    flex: 0 !important;
}

.custom_data_table thead tr th.unsort::after {
    background-image: url(../assets/images/unsort.svg);
    background-position: center;
    background-repeat: no-repeat;
    content: '';
    position: absolute;
    top: 12px;
    margin-left: 4px;
    width: 10px;
    height: 10px;
}

.custom_data_table thead tr th.sort-desc::after {
    background-image: url(../assets/images/unsort-one.svg);
    background-position: center;
    background-repeat: no-repeat;
    content: '';
    position: absolute;
    top: 12px;
    margin-left: 4px;
    width: 10px;
    height: 10px;
}

.custom_data_table thead tr th.sort-asc::after {
    background-image: url(../assets/images/unsort-one.svg);
    transform: rotate(180deg);
    background-position: center;
    background-repeat: no-repeat;
    content: '';
    position: absolute;
    top: 12px;
    margin-left: 4px;
    width: 10px;
    height: 10px;
}

.custom_data_table .ant-table-tbody .ant-table-cell,
.custom_data_table tbody tr td {
    white-space: nowrap;
    padding: 12px 30px;
    color: #424242;
    font-weight: 500;
    font-size: 13px;
}

.custom_data_table .ant-table-tbody .ant-table-cell {
    padding: 11.6px 30px;
    width: auto;
    min-width: 250px;
}

.custom_data_table tfoot.ant-table-summary,
.custom_data_table tfoot {
    background-color: #F5F5F5;
    border-top: 2px solid #F5F5F5;
}

.custom_data_table tfoot tr td {
    white-space: nowrap;
    padding: 12px 30px;
    color: #212121;
    font-weight: 700;
    font-size: 13px;
}

.custom_data_table tbody tr td i {
    cursor: pointer;
}


.custom_data_table tbody tr td img {
    width: 26px;
    height: 26px;
    object-fit: cover;
    margin-right: 8px;
}

.custom_data_table tbody tr td img.company_name_image {
    border-radius: 100px;
}

.custom_data_table tbody tr td img.im_image {
    width: 16px;
    height: 16px;
}

.custom_data_table tbody tr td img.campaign_identity {
    width: 26px;
    height: 26px;
    margin-left: 50px;
}

.custom_data_table tbody tr td a {
    color: #424242;
    font-weight: 500;
    text-decoration: none;
    display: block;
}

.custom_data_table tbody tr:nth-child(even) {
    background-color: #FBFBFF;

}

.table_area_spacing .card-footer {
    margin-top: 12px;
}

.custom_data_table tbody tr td .css-b62m3t-container {
    min-width: 100px;
}

/* .custom_data_table tbody tr td:nth-child(4) {
    color: #424242;
} */

.custom_data_table tbody tr td .custom_drop_button {
    padding: 0;
}

.custom_data_table tbody tr td button.dropdown-toggle {
    color: #E0E0E0;
    font-size: 14px;
}

.custom_data_table tbody tr td button i.fa-ellipsis-v {
    color: #E0E0E0;
    font-size: 14px;
}

.table_area_spacing .search-table-main button.btn {
    padding: 8px 12px;
    height: 40px;
}

.custom_data_table tbody tr td span.badge-custom-2 {
    padding: 7px;
}

.custom_data_table .afteradduser ul {
    margin: 0;
    padding: 0;
}

.custom_data_table .afteradduser ul li {
    list-style-type: none;
}

.custom_data_table tbody tr td:first-child button {
    padding: 0;
}

.custom_data_table tbody tr td .table_add_new_user {
    width: 13px;
    height: 13px;
    border-radius: 0;
    cursor: pointer;
    object-fit: contain;
    margin-right: 4px;
}

.custom_data_table tbody tr td .table_add_new_user_gray {
    width: 20px;
    cursor: pointer;
    height: 20px;
    border-radius: 0;
    object-fit: contain;
}

.custom_data_table tbody tr td .table_add_new_user_gray:hover path {
    fill: #a1a3f7;
}

.custom_data_table tbody tr td .user_bg_text {
    display: flex;
    align-items: center;
}

.custom_data_table .afteradduser ul li button {
    padding: 0;
}

.custom_data_table .css-1xc3v61-indicatorContainer,
.custom_data_table .css-1fdsijx-ValueContainer {
    padding: 0;
}

.custom_data_table .css-1dimb5e-singleValue {
    font-size: 12px;
    padding: 0 4px;
}

.custom_data_table .css-qbdosj-Input {
    margin: 0;
    padding: 0;
}

.custom_data_table .form-control .css-13cymwt-control {
    border: none;
    height: 35px;
    min-height: 35px;
}

.main_contain_area .child-pagination-row select {
    border: none;
    color: #687182;
    font-size: 14px;
}

.main_contain_area .pagination-text-end span {
    border: none;
    color: #687182;
    padding-right: 15px;
    font-size: 14px;
}

.main_contain_area .pagination-text-end button {
    border: 1px solid #EEEEEE;
    border-radius: 4px;
    background-color: transparent;
    padding: 4px 15px;
    font-size: 12px;
}

.custom_modal_popup_spacing table tbody tr td {
    padding: 24px 10px;
    font-size: 13px;
}

::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    border-radius: 100px;
}

::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 100px;
}

::-webkit-scrollbar-thumb {
    background: #e0e0e0;
    border-radius: 100px;
}

.colour_tags_design .badge {
    height: 26px;
    border-radius: 4px;
    line-height: normal;
    font-weight: 500;
    font-size: 12px;
    width: 71px;
}

.colour_tags_design .badge.bg-success {
    background-color: rgba(234, 251, 230, 0.5) !important;
    border: 1px solid #EAFBE6;
    color: #008500;
}

.colour_tags_design .badge.bg-danger {
    background-color: rgba(255, 233, 236, 0.5) !important;
    border: 1px solid #FFE9EC;
    color: #ED0017;
}

.colour_tags_design .badge.bg-warning {
    background-color: rgb(241 196 15 / 13%) !important;
    border: 1px solid #f1c40f14;
    color: #f1c40f;
}

/* .table_first_th thead tr th:first-child {
    visibility: hidden;
} */

.page_loader_gif {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    display: grid;
    place-items: center;
}

.page_loader_gif::before {
    position: absolute;
    left: 0;
    top: 0;
    filter: blur(100%);
    width: 100%;
    height: 100%;
    background-color: #ffffffc4;
    content: '';
}

.page_loader_gif img {
    width: 100px;
    position: relative;
}

.custom_filter_btn .form-control {
    border: 1px solid #EEEEEE;
    font-weight: 400;
    font-size: 14px;
}

.css-1xc3v61-indicatorContainer,
.ant-select-suffix {
    background-image: url(../assets/images/all_down_arrow.svg);
    width: 26px;
    height: 26px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 0;
    transform: translate(5px, 0px);
}

.css-1xc3v61-indicatorContainer {
    transform: translate(-6px, 0px);
}

.css-1xc3v61-indicatorContainer svg,
.ant-select-suffix svg {
    display: none !important;
}

.map_table_design .custom_data_table thead tr th,
.map_table_design .custom_data_table tbody tr td {
    padding: 6px 15px;
}

.map_table_design .custom_data_table tbody tr td img {
    width: 16px;
    height: 16px;
    border-radius: 0;
    object-fit: none;
    cursor: pointer;
}

.table_border_radius.map_table_design.video_upload_table .form-control {
    padding: 6px 10px;
    color: #212121;
    font-size: 14px;
    height: auto;
    border: 1px solid #e9e9e9;
    font-weight: 500;
}

.list_advertiser_table .custom_data_table tbody tr td {
    padding: 8.5px 30px;
}

.list_advertiser_table .table_border_radius .ant-select .ant-select-selector {
    border-radius: 4px;
}

.list_advertiser_table .table_border_radius .ant-select.approvedclass .ant-select-selector {
    border: 1px solid #CBF4C1;
    background: rgba(234, 251, 230, 0.50);
}

.list_advertiser_table .table_border_radius .ant-select.approvedclass .ant-select-selector .ant-select-selection-item {
    color: #008500;
    padding: 0;
}

.list_advertiser_table .table_border_radius .ant-select.newclass .ant-select-selector {
    border: 1px solid #EEE;
    background: #F5F5F5;
}

.list_advertiser_table .table_border_radius .ant-select.newclass .ant-select-selector .ant-select-selection-item {
    color: #616161;
    padding: 0;
}

.list_advertiser_table .table_border_radius .ant-select.draftclass .ant-select-selector {
    border: 1px solid #FECD81;
    background: #FFF4E2;
}

.list_advertiser_table .table_border_radius .ant-select.draftclass .ant-select-selector .ant-select-selection-item {
    color: #FA8E02;
    padding: 0;
}

.list_advertiser_table .table_border_radius .ant-select.declined .ant-select-selector {
    border: 1px solid #ffc8cc;
    background: #FFE9EC;
}

.list_advertiser_table .table_border_radius .ant-select.declined .ant-select-selector .ant-select-selection-item {
    color: #ef6462;
    padding: 0;
}

.list_advertiser_table .ant-select-arrow {
    display: none;
}

.list_advertiser_table .table_border_radius .ant-select.suspendedclass .ant-select-selector .ant-select-selection-item {
    color: #9e9e9e;
    padding: 0;
}

.list_advertiser_table .table_border_radius .ant-select .ant-select-selector {
    width: 100px;
    text-align: center;
}

.list_advertiser_table .table_border_radius .ant-select .ant-select-selector:hover {
    border-color: #bdbdbd !important;
}

.list_advertiser_table .ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    border-color: transparent;
}

/* .ant-select-dropdown {
    width: 120px !important;
} */

.new_tree_drop-design .ant-select-dropdown {
    width: auto !important;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    border-radius: 4px !important;
    padding: 5px 8px;
    color: #6366f1 !important;
    font-weight: 600;
    font-size: 13px !important;
    background-color: #eff0fe !important;
}

.ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: rgba(0, 0, 0, 0.04);
    font-size: 13px !important;
}

.ant-select-dropdown .ant-select-item {
    font-size: 13px !important;
    padding: 5px 8px;
}

.wallet_hover,
.edit_hover {
    cursor: pointer;
}

.wallet_hover {
    margin-right: 10px;
}

.wallet_hover path {
    fill: #bdbdbd;
}

.wallet_hover:hover path {
    fill: #424242;
}

.edit_hover:hover path {
    fill: #424242;
}

.firs_td_name_text {
    display: flex;
    align-items: center;
}

.name_text_round {
    width: 32px;
    height: 32px;
    border-radius: 100px;
    background-color: #EFF0FE;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.name_text_round span {
    font-weight: 500;
    font-size: 13px;
    display: block;
    text-align: center;
    color: #616161;
    text-transform: uppercase;
    line-height: 0px;
}

.user_modal_table tbody tr td {
    padding: 17.5px 30px;
}

.custom_data_table tbody tr td img.modal_img_social {
    height: 16px;
    object-fit: contain;
    width: 16px;
}

.custom_select_width_design {
    width: 180px;
}

.custom_select_width_design .css-1fdsijx-ValueContainer {
    padding: 2px 4px;
}

.custom_modal_pop_over .modal-content {
    border-radius: 8px;
}

.custom_data_table tbody tr td .limited_text span,
.custom_data_table tbody tr td .limited_text a {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    border: 1px solid transparent;
}

.custom_data_table tbody tr td .limited_text_two span,
.custom_data_table tbody tr td .limited_text_two a {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 230px;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    border: 1px solid transparent;
}

.custom_data_table tbody tr td img.disabled_icon {
    width: 16px;
    height: 16px;
    filter: opacity(0.3);
}

.table_radius_cls {
    border-radius: 8px !important;
}

.custom_modal_design.custom_modal_pop_over.user_padding_modal_popup thead tr th {
    padding: 10px 15px 7px;
}

.custom_modal_design.custom_modal_pop_over.user_padding_modal_popup tbody tr td {
    padding: 17px 15px;
}

.no_search_record img {
    display: block;
    margin: 162px auto 28px;
}

.no_search_record h4 {
    color: #212121;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 4px;
}

.no_search_record span {
    color: #BDBDBD;
    font-size: 13px;
    font-weight: 400;
    line-height: normal;
    display: block;
    padding-bottom: 100px;
}

.eCPM_class {
    text-transform: inherit !important;
}

.new_ant_table tbody,
.new_ant_table td,
.new_ant_table tfoot,
.new_ant_table th,
.new_ant_table thead,
.new_ant_table tr {
    border-color: #eee;
    border-style: solid;
    border-width: 1px;
}

.ant-table-thead .ant-tooltip-open {
    display: none !important;
}

.tableFixHead {
    height: auto;
    max-height: calc(100vh - 236px);
    position: relative;
    overflow: auto;
    border-collapse: separate;
}

.after_before_add_tag_table_height .tableFixHead {
    height: auto;
    max-height: 70.3vh;
}

.tableFixHead table thead th,
.tableFixHead table tfoot td {
    position: sticky !important;
    top: 0;
    z-index: 9;
    background-color: #F5F5F5;
}

.tableFixHead table tfoot td {
    bottom: -1px;
}

.tableFixHead table th.fixed-sticky,
.tableFixHead table tfoot td.fixed-sticky {
    z-index: 10;
}

.tableFixHead table th.fixed-sticky:nth-child(2) {
    left: 108px;
}

.tableFixHead table td.fixed-sticky:nth-child(2) {
    left: 108px;
}


.tableFixHead table th.fixed-sticky:nth-child(3) {
    left: 248px;
}

.tableFixHead table th.fixed-sticky:nth-child(3),
.tableFixHead table td.fixed-sticky:nth-child(3) {
    left: 268px;
    box-shadow: inset -1px 0px 0px #EEEEEE;
}

.tableFixHead.fixedfourcolumn table th.fixed-sticky:nth-child(3),
.tableFixHead.fixedfourcolumn table td.fixed-sticky:nth-child(3) {
    box-shadow: none;
}

.tableFixHead table th.fixed-sticky:nth-child(4) {
    left: 360px;
}

.tableFixHead table th.fixed-sticky:nth-child(4),
.tableFixHead table td.fixed-sticky:nth-child(4) {
    left: 379px;
}

.tableFixHead table th.fixed-sticky:nth-child(5),
.tableFixHead table td.fixed-sticky:nth-child(5) {
    left: 739px;
    box-shadow: inset -1px 0px 0px #EEEEEE;
}

.tableFixHead.fixedfirstcolumn table th.fixed-sticky,
.tableFixHead.fixedfirstcolumn table td.fixed-sticky {
    box-shadow: inset -1px 0px 0px #EEEEEE;
}

.tableFixHead table th.fixed-sticky,
.tableFixHead table td.fixed-sticky {
    position: sticky;
    left: 0;
}

.no_found_table_screen.tableFixHead table th.fixed-sticky,
.no_found_table_screen.tableFixHead table td.fixed-sticky {
    position: relative !important;
    left: 0;
}

.tableFixHead table td.fixed-sticky {
    z-index: 8;
}

.tableFixHead table tr:nth-child(odd) td.fixed-sticky {
    background-color: #ffffff;
}

.tableFixHead table tfoot tr:nth-child(even) td.fixed-sticky,
.tableFixHead table tfoot tr:nth-child(odd) td.fixed-sticky {
    background-color: #F5F5F5;
}

.tableFixHead table tr:nth-child(even) td.fixed-sticky {
    background-color: #FBFBFF;
}

.ant-select-dropdown {
    z-index: 1055 !important;
}

.custom_container_table table tbody tr:last-child {
    border-color: transparent;
}

.custom_data_table th.fixedthwidth {
    width: 76px;
    padding: 10px 0px 7px !important;
    text-align: center;
}

.custom_data_table td.fixedthwidth {
    width: 76px;
    padding: 10px 0px 7px !important;
    text-align: center;
}

.custom_data_table td.img_with_content_td {
    width: 950px;
    padding: 0;
}

/* .row_on_check_css {
    background-color: #eff0fe;
} */

.clickable_text {
    color: #2C6AB7 !important;
    display: block;
}

.clickable_text svg {
    opacity: 0;
    margin-left: 6px;
    margin-top: -2px;
}

.clickable_text:hover {
    text-decoration: underline;
}

.clickable_text:hover svg {
    opacity: 1;
}

.table_border_radius.adjust_td_in_1600_view .table-responsive tbody tr td.fixedthwidth.checkbox_td_column {
    width: 5%;
}

.table_border_radius.adjust_td_in_1600_view .table-responsive tbody tr td.fixedthwidth.action_td_column {
    width: 4%;
}

.table_border_radius.adjust_td_in_1600_view .table-responsive thead tr th.fixedthwidth.creation_action_table_cls,
.table_border_radius.adjust_td_in_1600_view .table-responsive tbody tr td.fixedthwidth.action_td_column.creation_action_table_cls {
    padding: 10px 20px 7px 0px !important;
}

.table_border_radius.adjust_td_in_1600_view .table-responsive tbody tr td.status_td_column {
    width: 5%;
}

.before_upload_creative_data_table .table tbody tr td:nth-child(1) {
    width: 3% !important;
}

.before_upload_creative_data_table .table thead tr th:nth-child(2),
.before_upload_creative_data_table .table tbody tr td:nth-child(2) {
    padding: 10px 15px 7px 0px !important;
}

.new_badges_design .badge {
    height: 26px;
    width: 110px;
    min-height: auto;
    font-size: 11px;
    border: 1px solid #ededed;
    display: flex;
    align-items: center;
    justify-content: center;
}

.new_badges_design .badge.danger_tag {
    border: 1px solid #ffc8cc !important;
    background-color: #FFE9EC !important;
    color: #ef6462 !important;
}

.new_badges_design .badge.success_tag {
    border: 1px solid #CBF4C1 !important;
    background-color: rgba(234, 251, 230, 0.50) !important;
    color: #008500 !important;
}

.new_badges_design .badge.warning_tag {
    border: 1px solid #EEE !important;
    background-color: #F5F5F5 !important;
    color: #616161 !important;
}

.new_badges_design .custom_data_table .ant-table-tbody .ant-table-cell,
.new_badges_design .custom_data_table tbody tr td {
    padding: 8.5px 30px;
}