.checkbox_filter_area_design {
    border-radius: 8px;
    border: 1px solid #E0E0E0;
    position: relative;
    height: auto;
    width: max-content;
}

.checkbox_filter_area_design .form-control {
    border-radius: 0;
    border: none;
    /* border-right: 1px solid #E0E0E0; */
    width: 164px;
    padding: 0;
}

.checkbox_filter_area_design .input-group-text {
    border-radius: 0;
    border: none;
}

.checkbox_filter_area_design .form-control:focus {
    border-color: #e0e0e0 !important;
}

.checkbox_filter_area_design .box_list_design .one_box {
    border-left: 1px solid #E0E0E0;
}

.checkbox_filter_area_design .box_list_design .one_box:first-child {
    border: none;
}

.checkbox_filter_area_design ul {
    border-top: 1px solid #E0E0E0;
    padding: 5px 6px;
    margin: 0;
    width: auto;
    min-width: 198px;
    height: 263px;
    overflow-y: scroll;
}

.checkbox_filter_area_design ul li {
    list-style-type: none;
    padding: 5px 6px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.checkbox_filter_area_design ul li.active_checkbox_li {
    border-radius: 4px;
    background-color: #EFF0FE;
}

.checkbox_filter_area_design ul li.active_checkbox_li span {
    color: #6366F1;
    font-weight: 600;
}

.checkbox_filter_area_design ul li input[type="checkbox"]:checked+span {
    color: #424242;
}

.checkbox_filter_area_design ul li.active_checkbox_li svg path {
    stroke: #6366F1;
}

.checkbox_filter_area_design ul li span {
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    transform: translateY(0px);
    padding-left: 8px;
}

.form-check-input.partial_checkbox:checked[type=checkbox] {
    background-image: url(../assets/images/line_checkbox.svg);
}



/* start css for different layout */
.ant-select-dropdown {
    box-shadow: none;
    border: 1px solid #E0E0E0;
}

.ant-cascader-dropdown .ant-cascader-menu {
    height: 250px;
    border: none;
    padding: 6px;
}

.ant-cascader-menu-item-content {
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
}

.ant-cascader-menu-item-active {
    background-color: #EFF0FE !important;
}

.anticon.anticon-right {
    background-image: url(../assets/images/checkbox_arrow.svg);
    width: 16px;
    height: 16px;
}

.anticon.anticon-right svg {
    display: none;
}

.ant-cascader-menu-item-active .ant-cascader-menu-item-content {
    font-weight: 600;
    color: #6366f1;
}

.ant-cascader-menu-item-active .anticon.anticon-right svg {
    display: none;
}

.ant-cascader-menu-item-active .anticon.anticon-right {
    background-image: url(../assets/images/checkbox_arrow_blue.svg);
    width: 16px;
    height: 16px;
}

.ant-cascader-menu-item-expand-icon {
    line-height: 0;
}

.ant-cascader-checkbox {
    transform: translate(0, 1px) !important;
}

.ant-cascader-dropdown .ant-cascader-menu-item {
    padding: 8px 6px;
}

.ant-cascader-checkbox.ant-cascader-checkbox-checked .ant-cascader-checkbox-inner:hover,
.ant-cascader-checkbox.ant-cascader-checkbox-checked .ant-cascader-checkbox-inner {
    background-color: #6366f1 !important;
    border-color: #6366f1 !important;
}

.ant-cascader-checkbox:hover .ant-cascader-checkbox-inner {
    border-color: #6366f1 !important;
}

.ant-cascader-checkbox.ant-cascader-checkbox-indeterminate .ant-cascader-checkbox-inner::after {
    background-color: #6366f1;
}

.ant-select-dropdown.ant-cascader-dropdown.ant-select-dropdown-placement-topLeft {
    --arrow-x: 0px !important;
    --arrow-y: 0 !important;
    left: 0px !important;
    top: 0 !important;
}

.ant-cascader-dropdown .ant-cascader-checkbox .ant-cascader-checkbox-inner:after {
    background: url(../assets/images/checkbox_check.svg) !important;
    width: 15px;
    height: 15px;
    border: none;
    transform: rotate(0deg);
    left: -1px;
    top: 0px;
}

.ant-cascader-dropdown .ant-cascader-checkbox-indeterminate .ant-cascader-checkbox-inner:after {
    background: #6366f1 !important;
    top: 50% !important;
    inset-inline-start: 50%;
    width: 8px !important;
    height: 8px !important;
    left: 50% !important;
    border: 0 !important;
    transform: translate(-50%, -50%) scale(1) !important;
    opacity: 1;
}

.ant-cascader-checkbox-inner {
    overflow: hidden;
}