.payment_area_page {
    width: 35%;
    height: auto;
    padding: 0;
    margin: 100px auto;
}

.payment_area_page img {
    margin: 0px 0 20px;
}

.payment_area_page h3 {
    font-weight: 700;
    font-size: 20px;
    color: #212121;
}

.payment_area_page p {
    font-weight: 400;
    font-size: 16px;
    color: #757575;
}

.payment_area_page .table {
    background-color: #FAFAFA;
    white-space: nowrap;
    margin: 36px auto 24px;
    border-radius: 8px;
    width: 75%;
}

.payment_area_page .btn {
    padding: 9px 38px;
}

.payment_area_page .table thead {
    border-bottom: 0.8px solid #E0E0E0;
}

.payment_area_page .table tr th {
    padding: 22px 40px;
}

.payment_area_page .table tr td {
    padding: 12px 40px;
}

.payment_area_page .table tr th:first-child,
.payment_area_page .table tr td:first-child {
    color: #9E9E9E;
    text-align: left;
}

.payment_area_page .table tr th:last-child,
.payment_area_page .table tr td:last-child {
    color: #212121;
    text-align: right;
}