@media only screen and (min-width: 1921px) {

  .step_input_fields label img {
    transform: translateY(-2px);
  }

  .modal_custom_datepicker_design .form-control {
    width: 325px;
  }

  .login_img_area p {
    padding: 0;
  }

  .my_custom_grid_system {
    display: flex;
  }

  .my_custom_grid_system .custom_col_grid_9 {
    width: 75%;
  }

  .my_custom_grid_system .custom_col_grid_3 {
    width: 25%;
    margin-top: -272px;
  }

  .alert_form_area_design {
    width: 47%;
  }

}

@media only screen and (min-width: 1300px) and (max-width: 1600px) {
  .login_img_area p {
    padding: 0 30px 50px;
  }

  .login_img_area img {
    width: 560px;
  }

  .form_fields h1 {
    padding-top: 75px;
  }

  .login_page {
    height: 740px;
  }

}

@media only screen and (min-width: 768px) and (max-width: 1366px) {

  .login_img_area img {
    width: 560px;
  }

  .login_img_area p {
    padding: 10px 110px 40px;
  }

  .form_side_area {
    margin-top: 100px;
  }

  .login_page {
    height: auto;
  }

  .login_img_area p {
    padding: 0 30px 50px;
  }
}

@media only screen and (max-width: 1920px) {
  .my_custom_grid_system {
    display: flex;
  }

  .my_custom_grid_system .custom_col_grid_9 {
    width: 75%;
  }

  .my_custom_grid_system .custom_col_grid_3 {
    width: 25%;
    margin-top: -272px;
    padding: 0px;
  }

  .custom_col_grid_3 .col-md-12 {
    padding-right: 0;
  }

  .custom_col_grid_3 .dash_board_box {
    width: 100%;
  }

  .larg_view {
    display: block;
  }

  .small_view {
    display: none;
  }

}

@media only screen and (max-width: 1800px) {

  .dash_board_box h4 {
    font-size: 28px;
  }

  .make_custom_chart_position .apexcharts-legend.apexcharts-align-center.apx-legend-position-right {
    right: 40px !important;
  }

  .make_custom_chart_position {
    transform: translate(-51%, -26%);
  }

  .meter_niddle_area {
    height: 50%;
  }

  .table_border_radius.adjust_td_in_1600_view .table-responsive tbody tr td.fixedthwidth.checkbox_td_column {
    width: 5%;
  }

  .table_border_radius.adjust_td_in_1600_view .table-responsive tbody tr td.fixedthwidth.action_td_column {
    width: 4%;
  }

  .table_border_radius.adjust_td_in_1600_view .table-responsive tbody tr td.status_td_column {
    width: 5%;
  }
}

@media only screen and (max-width: 1700px) {

  .dash_board_box h4 {
    font-size: 28px;
  }

  .make_custom_chart_position .apexcharts-legend.apexcharts-align-center.apx-legend-position-right {
    right: 40px !important;
  }

  .make_custom_chart_position {
    transform: translate(-51%, -26%);
  }

  .meter_niddle_area {
    height: 50%;
  }

}

@media only screen and (max-width: 1600px) {

  .my_custom_grid_system .custom_col_grid_9 {
    width: 100%;
  }

  .meter_niddle_area {
    height: 57%;
  }

  .my_custom_grid_system .custom_col_grid_3 {
    width: 100%;
    margin-top: 16px;
    padding: 0px 12px;
  }

  .custom_col_grid_3 {
    display: inline-flex;
  }

  .custom_col_grid_3 .graph_areas_side {
    /* width: 345px; */
    margin-top: 0;
  }

  .resp_h_250 {
    height: 250px;
  }

  .my_custom_grid_system {
    display: flex;
    /* flex-direction: column-reverse; */
  }

  .tab_pane_one .meter_text {
    bottom: 20%;
  }

  .meter_niddle_area .meter_niddle {
    top: 47px;
  }

  .tab_pane_one .meter_text h4 {
    font-size: 13px;
  }

  .tab_pane_one .meter_text p {
    font-size: 11px;
  }

  .weather_with_four_tabs .tab-pane.tab_pane_one h2.meter_value {
    top: 45%;
  }

  .weather_with_four_tabs .tab-pane.tab_pane_one .speedo_meter_data ul li {
    margin-bottom: 12px;
  }

  .meter_niddle_area .meter_niddle::after {
    height: 18%;
  }

  .custom_order_9 {
    order: 1;
  }

  .custom_order_3 {
    order: 2;
  }

  .custom_order_9_mid {
    order: 3;
  }

  .weather_with_four_tabs .tab-pane.tab_pane_one h2.new_clr {
    padding-top: 0px;
  }

  .speedo_meter_data {
    display: none;
  }

  .weather_with_four_tabs .tab-pane.tab_pane_one.img_cls_three ul {
    padding: 0 6px 0 0;
    height: 212px;
  }

  .hide_1600 {
    display: none;
  }

  .tab_pane_one .speedometer {
    width: 265px;
    height: 250px !important;
    margin: 0 auto;
  }

  .resp-mb-10 {
    margin-bottom: 10px;
  }

  .sports_details_design li {
    padding: 16px 50px 16px 0;
  }

  .sport_widget_scroll_area {
    height: 185px;
  }

  .media_planner_page .boxs_line_design .accordion-item {
    width: 350px;
  }

  .weather_with_four_tabs .tab-pane.tab_pane_one {
    height: 250px;
  }

  .weather_with_four_tabs .tab-pane.tab_pane_one .weather_cloud_img {
    margin: 22px auto 0;
  }

  .weather_with_four_tabs .tab-pane.tab_pane_one img.sun_cloud_img {
    top: 0;
  }

  .weather_with_four_tabs .tab-pane.tab_pane_one.img_cls_three ul li:last-child {
    margin-bottom: 0;
  }

  .custom_col_grid_3 .col-md-12 {
    width: 25%;
  }

  .custom_col_grid_3 .dash_board_box {
    width: 100%;
  }

  .larg_view {
    display: none;
  }

  .small_view {
    display: block;
  }

  .alert_form_area_design {
    width: 70%;
  }

  .custom_data_table td.img_with_content_td {
    padding: 0;
  }

  .custom_list_boxs ul {
    max-height: 145px;
  }

  .media_planner_page .boxs_line_design.boxs_line_design_updated_cls {
    width: calc(100% - 545px);
    padding-right: 10px;
  }

  .report_page .media_planner_page .boxs_line_design.boxs_line_design_updated_cls {
    width: calc(100% - 690px);
    padding-right: 10px;
  }


  .table_border_radius.adjust_td_in_1600_view .table-responsive tbody tr td.fixedthwidth.checkbox_td_column {
    width: 5%;
  }

  .table_border_radius.adjust_td_in_1600_view .table-responsive tbody tr td.fixedthwidth.action_td_column {
    width: 4%;
  }

  .table_border_radius.adjust_td_in_1600_view .table-responsive tbody tr td.status_td_column {
    width: 5%;
  }

  .only_slider .modal-content {
    width: 1400px;
    height: 99vh;
  }

  .custom_modal_design.review_creative_padding .modal-body {
    padding: 0 80px;
    margin: 0;
  }

  .only_slider .modal-body .carousel .carousel-control-next {
    right: -130px;
  }

  .only_slider .modal-body .carousel .carousel-control-prev {
    left: -130px;
  }

}

@media only screen and (max-width: 1441px) {
  .alert_form_area_design {
    width: 80%;
  }

  .new_ant_table_design .ant-table-expanded-row.ant-table-expanded-row-level-1 .ant-table {
    margin-inline: 12.4% -16px !important;
  }

  .new_ant_table_design.new_ant_table_design_history .ant-table-expanded-row.ant-table-expanded-row-level-1 .ant-table {
    margin-inline: 2.9% 0% !important;
  }

  .new_ant_table_design .temp_table_ord .ant-table-expanded-row.ant-table-expanded-row-level-1 .ant-table {
    margin-inline: 13.8% -16px !important;
  }
}

@media only screen and (max-width: 768px) {

  .login_img_area p {
    padding: 0 30px 50px;
  }

}