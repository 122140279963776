.custom_modal_design .modal-title.h4 {
    font-weight: 400;
    color: #0A0A0A;
    font-size: 16px;
}

.mt-16 {
    margin-top: 16px;
}

.custom_modal_design .modal-body {
    padding: 16px 24px 24px;
}

.custom_modal_design.review_creative_padding .modal-body {
    padding: 0 65px;
    margin: 10px;
}

.custom_modal_design [data-dismiss] {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: #000;
    background: transparent url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e) center/1em auto no-repeat;
    border: 0;
    border-radius: 0.375rem;
    opacity: .5;
}

.custom_modal_design .form-control::placeholder {
    color: #bdbdbd;
    font-weight: 400;
}

.custom_modal_design label {
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 6px;
    color: #616161;
}

.custom_modal_design .form-control {
    color: #000;
    height: 40px;
    font-weight: 400;
    font-size: 14px;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
}

.active_border_hide {
    padding: 0 10px;
    cursor: pointer;
}

.this_cls_for_single_datepicker_design .input-group-text {
    padding: 0 8px;
}