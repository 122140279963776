.report_page .media_planner_page {
    margin-left: 92px;
}

.media_planner_page {
    margin-left: 215px;
    margin-top: 82px;
    margin-right: 12px;
}

.media_planner_page .filter_area_design {
    margin: 0;
    margin-right: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.media_planner_page .main_contain_area {
    margin: 0;
}

.media_planner_page .boxs_line_design {
    margin-bottom: 11px;
}

.media_planner_page .boxs_line_design {
    padding: 0;
    margin: 20px 10px 0 0px;
}

.media_planner_page .boxs_line_design.boxs_line_design_updated_cls {
    overflow-y: scroll;
    width: calc(100% - 785px);
    height: calc(100vh - 140px);
    padding-right: 10px;
}

.report_page .media_planner_page .boxs_line_design.boxs_line_design_updated_cls {
    overflow-y: scroll;
    width: calc(100% - 930px);
    height: calc(100vh - 140px);
    padding-right: 10px;
}

/* .media_planner_page .boxs_line_design li {
    list-style: none;
    width: 568px;
    height: auto;
    background-color: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #eee;
    padding: 12px 20px;
} */
.media_planner_page .boxs_line_design .accordion-item {
    width: 100%;
    margin-bottom: 16px;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    border: 1px solid #EEE;
}

.media_planner_page .boxs_line_design .accordion-item .accordion-button {
    box-shadow: none;
}

.media_planner_page .boxs_line_design .accordion-button {
    padding: 16px;
    align-items: start;
}

.media_planner_page .boxs_line_design .accordion-button:focus {
    box-shadow: none;
}

.media_planner_page .boxs_line_design .accordion-button::after {
    background-image: url(../assets/images/all_down_arrow.svg);
    width: 26px;
    height: 26px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 0;
}

.media_planner_page .boxs_line_design .accordion-button:not(.collapsed)::after {
    background-image: url(../assets/images/all_down_arrow.svg);
    transform: rotate(180deg);
}

.media_planner_page .boxs_line_design .accordion-button:not(.collapsed) {
    background-color: transparent;
}

.media_planner_page .boxs_line_design span {
    font-weight: 500;
    font-size: 14px;
    color: #757575;
    padding-bottom: 20px;
    display: block;
}

.media_planner_page .boxs_line_design span img {
    margin-right: 8px;
}

.media_planner_page .boxs_line_design h3 {
    font-size: 32px;
    font-weight: 600;
    display: flex;
    color: #212121;
    align-items: end;
}

.media_planner_page .boxs_line_design h3 .support_text {
    transform: translate(4px, -4px);
    display: flex;
}

.media_planner_page .boxs_line_design h3 img {
    width: 14px;
    height: 14px;
}

.media_planner_page .boxs_line_design h3 span {
    color: #008500;
    font-size: 11px;
}

.media_planner_page .boxs_line_design h3 span.box_little_text {
    font-size: 11px;
    color: #9E9E9E;
    font-weight: 600;
}

.custom_modification_bar .ant-progress-outer {
    padding-inline-end: calc(0em + 0px) !important;
}

.media_planner_page .boxs_line_design .custom_modification_bar .ant-progress-text {
    display: none;
}

.media_planner_page .boxs_line_design .custom_modification_bar .first_bar_label {
    color: #212121;
    font-size: 12px;
    font-weight: 700;
}

.media_planner_page .boxs_line_design .custom_modification_bar .first_bar_label.diff_label {
    font-weight: 500;
    color: #9E9E9E;
}

.media_planner_page .boxs_line_design .custom_modification_bar .second_bar_label {
    color: #9E9E9E;
    font-size: 12px;
    font-weight: 500;
}

.media_planner_page .boxs_line_design .custom_modification_bar .ant-progress-line {
    margin: 0;
    transform: translate(0px, -8px);
}

.media_planner_page .boxs_line_design .custom_modification_bar .ant-progress-bg {
    background-color: #6366f1;
}

.media_planner_page .boxs_line_design .custom_modification_bar .ant-progress-inner {
    background-color: transparent;
}

.media_planner_page .boxs_line_design .custom_modification_bar.gray_bg_bar .ant-progress-bg {
    background-color: transparent;
}

.media_planner_page .boxs_line_design .custom_modification_bar.gray_bg_bar .ant-progress-inner {
    background-color: #EEE;
}

.custom_country_drop_design .input-group-text {
    border-radius: 8px 0 0 8px;
    height: 40px;
}

.custom_country_drop_design .css-13cymwt-control,
.custom_country_drop_design .css-t3ipsp-control {
    border-left: none;
    border-radius: 0 8px 8px 0 !important;
}

.custom_country_drop_design .css-1fdsijx-ValueContainer {
    padding: 0;
}

.media_planner_page .main_contain_area::-webkit-scrollbar {
    display: none;
}

.media_planner_page .table_area_spacing {
    padding-right: 16px;
}