.react-datepicker {
    border: 1px solid #ddd !important;
}

.react-datepicker__header {
    background-color: #fff !important;
    border-color: #fff !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    border-radius: 0.3rem;
    background-color: #6366F1 !important;
    color: #fff !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-top: none;
    border-bottom-color: transparent;
    display: none;
}

.react-datepicker__day {
    margin: 7px 3px !important;
}

.react-datepicker {
    width: auto;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    font-size: 12px;
}

.react-datepicker__day-names {
    margin-top: 14px;
}

.react-datepicker__day-name {
    color: #718096 !important;
    font-size: 14px;
}