@media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) and (stroke-color:transparent) {

        img {
            image-rendering: auto;
            image-rendering: crisp-edges;
            image-rendering: pixelated;
            image-rendering: -webkit-optimize-contrast;
        }

        .step_input_fields .form-control,
        .step_input_fields .form-select {
            border-radius: 8px;
        }

        .form-control:focus,
        .css-b62m3t-container:focus,
        .form-select:focus {
            box-shadow: none;
            border: 1px solid #6366f1;
            outline: 4px solid #eff0fe;
            outline-offset: initial;
        }

        .select-custom .css-1fdsijx-ValueContainer {
            padding: 2px 12px;
        }

    }
}