.main_contain_area {
  background-color: #fff;
  width: -webkit-fill-available;
  width: -moz-available;
  /* transition: 0.3s; */
  margin-left: 212px;
  margin-top: 80px;
  margin-right: 12px;
  border-radius: 8px;
  position: relative;
  height: calc(100vh - (92px));
  height: -moz-calc(100vh - (92px));
  height: -webkit-calc(100vh - (92px));
  margin-bottom: 12px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.report_page .main_contain_area {
  /* transition: 0.3s; */
  margin-left: 92px;
}

.step_area {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 0.8px solid #e0e0e0;
  padding: 18px 20px;
  align-items: center;
}

.step_area h5 {
  font-weight: 500;
  font-size: 20px;
  color: #0a0a0a;
}

.steps_buttons ul {
  display: flex;
  list-style: none;
  align-items: center;
}

.css-tr4s17-option .checkbox {
  font-size: 14px;
}

.steps_buttons ul li .step_line {
  width: 28px;
  margin: 0 12px;
  opacity: 1;
  border: 2px solid #e0e0e0;
  border-radius: 100px;
}

.steps_buttons ul li .step_count {
  display: flex;
  align-items: center;
}

.steps_buttons ul li.active_steps .step_count p {
  background-color: #3a8cdb;
  border: 5px solid #e4f2fc;
  width: 32px;
  height: 32px;
  font-weight: 600;
}

.steps_buttons ul li.active_steps .step_count span {
  color: #212121;
  font-weight: 700;
}

.steps_buttons ul li.activated_steps .step_count p {
  background: #bedff8;
  border: 4px solid #e4f2fc;
  color: #3a8cdb;
}

.steps_buttons ul li.activated_steps .step_count p svg {
  font-size: 9px;
  transform: translateX(0px);
}

.steps_buttons ul li.activated_steps .step_count p svg path {
  fill: #2c6ab7;
}

.steps_buttons ul li .step_count p {
  width: 24px;
  height: 24px;
  background-color: #e0e0e0;
  color: #ffffff;
  font-weight: 400;
  font-size: 13px;
  border-radius: 100px;
  display: grid;
  margin-right: 8px;
  line-height: 21px;
  place-items: center;
}

.steps_buttons ul li .step_count span {
  font-weight: 500;
  font-size: 14px;
  color: #9e9e9e;
}

.step_form_area {
  padding: 0px 20px;
}

.row_devider {
  padding: 20px 0 24px;
}

.step_form_label h4 {
  font-weight: 700;
  font-size: 16px;
  color: #424242;
  padding-top: 6px;
}

.step_input_fields {
  width: 916px;
}

.step_input_fields label {
  font-weight: 600;
  margin-bottom: 4px;
  font-size: 13px;
  color: #757575;
}

.ant-switch.ant-switch-checked {
  background: #6366f1;
}

.css-1dimb5e-singleValue {
  font-weight: 600;
  color: #0a0a0a;
}

.step_input_fields .form-control,
.step_input_fields .form-select {
  height: 40px;
  border: 1px solid #e0e0e0;
  font-weight: 600;
  border-radius: 8px;
  color: #212121;
  padding: 8px 12px;
  font-size: 14px;
}

.step_input_fields .form-control:disabled {
  background-color: #fff;
}

.step_input_fields .input-group-text,
.step_input_fields .input-group-text {
  border-color: #e0e0e0;
}

.step_input_fields #basic-addon1 {
  width: 48px;
  font-weight: 500;
  text-align: center;
  display: block;
  line-height: 26px;
  border-radius: 8px 0 0 8px;
}

.react_tag_design .ReactTags__tagInput {
  width: max-content;
}

.react_tag_design .ReactTags__tagInputField {
  height: auto;
  min-height: 38px;
  font-weight: 600;
  border-radius: 0px;
  border: none;
  color: #0a0a0a;
  width: 270px;
  padding: 4px 16px;
  font-size: 14px;
}

.react_tag_design .ReactTags__tagInputField::placeholder {
  color: #bdbdbd;
  font-weight: 400;
}

.react_tag_design .ReactTags__tagInputField:focus {
  outline: none;
}

.react_tag_design .tag-wrapper.ReactTags__tag,
.react_tag_design_second .tag-wrapper.ReactTags__tag {
  background-color: #E4F2FC;
  border: 1px solid #71B8EF;
  border-radius: 6px;
  display: flex;
  align-items: center;
  color: #2C6AB7;
  padding: 0 6px;
  font-size: 14px;
  margin: 4px 0px 4px 4px;
}

.react_tag_design input:focus-visible,
.react_tag_design_second input:focus-visible {
  outline: none;
}

.react_tag_design .ReactTags__tags.react-tags-wrapper {
  width: 80.4%;
}

.custom_modal_design .react_tag_design .ReactTags__tags.react-tags-wrapper {
  width: 0;
  flex: 1 1 auto;
}

.react_tag_design .ReactTags__remove,
.react_tag_design_second .ReactTags__remove {
  border: none;
  padding-left: 5px;
  color: #bdbdbd;
  font-size: 18px;
  background-color: transparent;
}


.react_tag_design .tag-wrapper.ReactTags__tag:hover .ReactTags__remove,
.react_tag_design_second .tag-wrapper.ReactTags__tag:hover .ReactTags__remove {
  color: #616161;
}


.react_tag_design .ReactTags__selected,
.react_tag_design_second .ReactTags__selected {
  display: flex;
  width: 100%;
  border: 1px solid #E0E0E0;
  border-radius: 0 8px 8px 0;
  flex-wrap: wrap;
}

.react_tag_design_second .ReactTags__tags.react-tags-wrapper {
  width: 72%;
}

.react_tag_design_second.border_radius_full .ReactTags__selected {
  border-radius: 8px;
  border: none;
  outline: 1px solid #E0E0E0;
}

.react_tag_design_second.border_radius_full .ReactTags__tags.react-tags-wrapper {
  width: 100%;
}

.react_tag_design_second .ReactTags__tagInputField {
  height: auto;
  min-height: 38px;
  font-weight: 600;
  border-radius: 0px;
  border: none;
  color: #0a0a0a;
  width: 265px;
  padding: 4px 16px;
  font-size: 14px;
}

.react_tag_design_second .ReactTags__tagInputField::placeholder {
  color: #bdbdbd;
  font-weight: 400;
}

.step_input_fields textarea.form-control {
  height: 80px;
  font-family: "Nunito", sans-serif !important;
}

.step_input_fields .form-control::placeholder {
  color: #bdbdbd;
  font-weight: 400;
}

input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
  /* -webkit-text-fill-color: #fff !important; */
}

.form-control:focus,
.css-b62m3t-container:focus,
.form-select:focus {
  box-shadow: none;
  border-color: #6366f1 !important;
  outline: 4px solid #eff0fe;
  outline-offset: initial;
}

.react-datepicker__input-container .form-control:focus {
  box-shadow: none;
  border: 1px solid #e0e0e0;
  outline: none;
  outline-offset: initial;
}

.input-group-text {
  transition: 0.3s;
}


.upload_logo_area {
  border: 1px dashed #e0e0e0;
  height: auto;
  min-height: 80px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 8px 10px;
  align-items: center;
}

.upload_logo_area.file_upload_custom_design {
  min-height: auto;
}

.upload_logo_area.txt_file {
  height: 44px;
  min-height: 44px;
}

.upload_logo_area img {
  width: 56px;
  height: 56px;
  object-fit: cover;
  border-radius: 8px;
}

.upload_logo_area p {
  font-weight: 600;
  font-size: 14px;
  color: #616161;
}

.upload_logo_area span {
  font-weight: 500;
  font-size: 12px;
  color: #bdbdbd;
}

.upload_logo_area .upload_btn_img {
  background: #f5f5f5;
  height: 36px;
  width: 75px;
  border-radius: 6px;
  padding: 4px 17px 10px;
  position: relative;
  border: 1px solid #eeeeee;
  transition: 0.3s;
}

.upload_btn_design .btn:hover,
.upload_logo_area .upload_btn_img:hover {
  background-color: #e0e0e0;
  transition: 0.3s;
}

.upload_logo_area .form-control {
  width: 100%;
  opacity: 0;
  position: absolute;
  left: 0;
  height: 100%;
  top: 0;
}

.upload_logo_area .upload_btn_img span {
  font-weight: 700;
  font-size: 12px;
  color: #424242;
}

.default_btn.btn {
  background: #6366f1;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  height: 36px;
  line-height: 0px;
  display: flex;
  /* margin: 0; */
  align-items: center;
  padding: 8px 12px;
  color: #ffffff;
  text-decoration: none;
}

.default_btn_border_default_btn {
  background-color: transparent;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  height: 36px;
  line-height: 0px;
  display: flex;
  /* margin: 0; */
  align-items: center;
  padding: 8px 20px;
  border: 1px solid #9e9e9e;
  color: #9e9e9e;
}

.default_btn_border_default_btn:hover {
  border-color: #424242;
  color: #424242;
}

.default_btn.btn svg {
  margin-left: 4px;
}

.default_btn.btn:hover {
  background: #595CD9;
}

.default_btn.btn:active {
  background: #4547A9;
  color: #7375F2;
}

.default_btn.border_default_btn.btn {
  background: transparent;
  border: 1px solid #9e9e9e;
  color: #9e9e9e;
}

.default_btn.border_default_btn.btn:hover {
  border-color: #424242;
  color: #424242;
}

.button_area_bottom {
  padding: 20px;
}

.default_btn.btn span {
  padding: 0 0px 0 5px;
  font-size: 13px;
}

.joined_input_fields .css-t3ipsp-control,
.joined_input_fields .css-13cymwt-control {
  border-radius: 8px 0 0 8px !important;
}

.joined_input_fields .ant-select-selector {
  border-radius: 0px 8px 8px 0px !important;
}

/* .joined_input_fields .css-1xc3v61-indicatorContainer {
  display: none;
} */

.bg-badge-custom.badge.bg-primary,
.bg-badge-custom-2.badge.bg-primary {
  background-color: #F5F5F5 !important;
  height: 24px;
  border-radius: 4px;
  line-height: normal;
  font-weight: 500;
  font-size: 12px;
  color: #424242;
}

.default_btn_design.btn.btn-primary {
  background-color: #EEEEEE;
  border: 1px solid #EEEEEE;
  font-weight: 400;
  font-size: 14px;
  color: #424242;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.two_custom_datepicker_design .input-group-text {
  border-color: #e0e0e0;
}

.two_custom_datepicker_design .full_input_border .input-group-text {
  border: 1px solid #6366f1;
  transition: 0.3s;
}

.two_custom_datepicker_design .react-datepicker-wrapper {
  display: block;
  width: 89%;
}

.two_custom_datepicker_design .react-datepicker-wrapper .form-control {
  border-radius: 0 8px 8px 0;
}

.ant-select-tree-switcher svg {
  display: none;
}

.ant-select-tree-switcher.ant-select-tree-switcher_close {
  background-image: url(../assets/images/gray_add_icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 12px !important;
  margin: 0 10px 0 2px !important;
}

.ant-select-tree-treenode.ant-select-tree-treenode-switcher-close .ant-select-tree-switcher.ant-select-tree-switcher_close:hover {
  background-image: url(../assets/images/gray_plus_hover.svg);
}

.ant-select-tree-switcher.ant-select-tree-switcher_open {
  background-image: url(../assets/images/gray_minus_icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 12px !important;
  margin: 0 10px 0 2px !important;
}

.ant-select-tree-switcher.ant-select-tree-switcher_open:hover {
  background-image: url(../assets/images/gray_minus_hover.svg);
}

.ant-select-tree-node-content-wrapper.ant-select-tree-node-content-wrapper-close:hover {
  background-color: transparent;
}

/* .ant-tree-select-dropdown .ant-select-tree .ant-select-tree-node-content-wrapper:hover,
.ant-tree-select-dropdown .ant-select-tree .ant-select-tree-checkbox+span:hover {
  background-color: transparent;
} */

.ant-tree-select-dropdown .ant-select-tree .ant-select-tree-indent-unit {
  display: inline-block;
  width: 50px;
}

/* .border_right_trans.form-control {
  border-right: transparent;
} */

.css-894a34-indicatorSeparator {
  display: none !important;
}

.full_input_border {
  outline: 4px solid #eff0fe;
  border-radius: 6px;
}

.full_input_border .form-control {
  outline: none;
  border-left: 1px solid #e0e0e0;
}


.full_input_border .input-group-text {
  border: 1px solid #6366f1;
  transition: 0.3s;
}

.custom_search_bar.full_input_border .input-group-text {
  border: 1px solid #6366f1;
  transition: 0.3s;
}

.graph_areas_side .full_input_border.new_cls_for_reactselect {
  border-radius: 8px !important;
}

.graph_areas_side .full_input_border.new_cls_for_reactselect .input-group-text {
  border: 1px solid #6366f1;
  /* border-right-color: transparent; */
  outline: none;
  transition: 0s !important;
}

.graph_areas_side .full_input_border.new_cls_for_reactselect .css-t3ipsp-control {
  /* border-radius: 4px !important; */
  border: 1px solid #6366f1;
  outline: none !important;
  border-left: none !important;
  transition: 0s !important;
}

.custom_bg_transparent_disabled {
  background-color: #f5f5f5;
}