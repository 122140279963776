.new_drop_design_focus .select-custom .css-1fdsijx-ValueContainer {
    padding: 2px 30px;
}

.new_drop_design_focus .absolute_icons {
    position: absolute;
    left: 10px;
    top: 11px;
    z-index: 1;
}

.group_table_vertical_line table tr td.fixed-sticky {
    box-shadow: inset -1px 0px 0px #EEEEEE;
}

.group_table_vertical_line table tr td[rowspan]:nth-child(1) {
    box-shadow: inset -1px 0px 0px #EEEEEE;
}

.group_table_vertical_line table tr.table-dark {
    --bs-table-bg: #F5F5F5;
    border-color: #dee2e6;
}

.custom_data_table .dark_table_text {
    color: #0A0A0A;
    font-size: 13px;
    font-weight: 600;
}

.alert_message_box_design {
    width: 100%;
    height: auto;
    position: relative;
    /* overflow: hidden; */
    border-radius: 8px;
    background: rgba(255, 244, 226, 0.50);
}

.alert_message_box_design .alert-warning {
    color: #0A0A0A;
    padding: 0;
    font-size: 13px;
    font-weight: 600;
    border-radius: 8px 8px 0px 0px;
}

.alert_message_box_design .alert-dismissible .btn-close {
    padding: 0;
    position: relative;
    right: 12px;
}

.alert_message_box_design .error_svg_icon_box {
    width: 34px;
    height: 34px;
    padding: 10px;
    border-radius: 8px 0px 0px 0px;
    background: #FF9F29;
    display: inline-flex;
    margin-right: 8px;
}

.two_btn_with_form {
    padding: 0px 16px 18px 16px;
}

.two_btn_with_form .form-control {
    border: 1px solid #E0E0E0;
    background-color: transparent;
}

.z_index_10 {
    z-index: 10;
}

.border_radius_css {
    border-radius: 0 8px 8px 0 !important;
}

.new_drop_design_focus .select2-design {
    left: 33px;
}

.new_drop_design_focus .value_text {
    width: 124px;
}

.new_drop_design_focus p {
    font-size: 13px;
    padding: 0;
}

.before_create_report_design {
    width: 100%;
    height: calc(100vh - 190px);
    border: 1px solid #EEEEEE;
    margin: 16px 0 0px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.before_create_report_design img {}

.before_create_report_design h3 {
    color: #212121;
    font-size: 16px;
    font-weight: 600;
    padding: 27px 0 4px;
}

.before_create_report_design p {
    color: #BDBDBD;
    font-size: 13px;
    font-weight: 400;
}

.last_row_total_issue {
    background-color: #FBFBFF !important;
    box-shadow: inset -1px 0px 0px #EEEEEE !important;
}