.dash_board_box {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 12px;
    width: calc(100% - (16px));
}

.dash_board_box img {
    width: 24px;
    margin-right: 8px;
}

.red_circle_img {
    padding: 4px 0 0;
}

.red_circle_img img {
    width: 20px;
    margin-right: 8px;
}

.dash_board_box h4 {
    font-weight: 600;
    font-size: 32px;
    color: #212121;
    margin: 0;
    padding: 15px 0 8px;
}

.dash_board_box h4 .active_user_text {
    font-weight: 600;
    font-size: 12px;
    color: #BDBDBD;
    transform: translate(4px, -7px);
}

.dash_board_box .bottom_graph_content small {
    font-weight: 700;
    font-size: 11px;
    padding-right: 4px;
}

.dash_board_box .bottom_graph_content small img {
    width: 11px;
    margin-right: 3px;
}

.dash_board_box p {
    color: #424242;
    font-size: 14px;
    font-weight: 500;
}

.dash_board_box span {
    color: #757575;
    font-size: 14px;
    font-weight: 500;
}

.dash_board_box .bottom_graph_content p {
    font-weight: 600;
    font-size: 11px;
    margin: 0;
    color: #9E9E9E;
}

.graph_areas_side {
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 16px;
    margin-top: 16px;
    overflow: hidden;
}

.graph_areas_side h4 {
    font-weight: 600;
    font-size: 18px;
    color: #0A0A0A;
}

.graph_areas_side .input-group {
    width: auto;
    height: 30px;
}

.graph_areas_side .custom_country_drop_design.input-group {
    height: auto;
}

.graph_areas_side .input-group-text {
    background-color: transparent;
    border: 1px solid #e0e0e0;
    padding-right: 0px;
}

.graph_areas_side .form-select:disabled {
    background-color: #fff;
}

.graph_areas_side .table_show_list {
    font-weight: 600;
    font-size: 13px;
    color: #bdbdbd;
    display: flex;
    align-items: center;
}

.graph_areas_side .table_show_list img {
    margin-right: 10px;
}

.graph_areas_side .form-select {
    font-weight: 600;
    font-size: 12px;
    color: #757575;
    padding-left: 10px;
    border: 1px solid #EEEEEE;
    border-left-color: transparent;
}

.graph_areas_side .form-select option {
    padding: 10px !important;
}

.graph_areas_side .form-select:focus {
    border: 1px solid #EEEEEE;
    border-left-color: transparent;
}

.graph_areas_side .apexcharts-toolbar {
    display: none;
}

.weather_with_four_tabs .nav-pills {
    position: absolute;
    left: 16px;
    top: 16px;
}

.tab_pane_one .location_area {
    position: absolute;
    right: 16px;
    top: 30px;
    font-weight: 500;
    font-size: 14px;
}


.tab_pane_one .location_area img {
    width: 14px;
    margin-right: 4px;
    transform: translateY(-2px);
}

/* .img_cls_one .location_area {
    color: #FFFFFF;
} */

.img_cls_two .location_area {
    color: #757575;
}

.img_cls_three .location_area {
    color: #757575;
    top: 29px;
}

.weather_with_four_tabs .nav-pills .nav-link.active,
.weather_with_four_tabs .nav-pills .show>.nav-link {
    background: #FFFFFF;
    border-radius: 37px;
    font-weight: 700;
    box-shadow: 0px 2px 20px rgba(51, 51, 51, 0.08);
    font-size: 16px;
    color: #212121;
    padding: 7px 11px;
    margin-right: 14px;
}

.weather_with_four_tabs .nav-link {
    padding: 7px 11px;
    color: #757575;
    font-weight: 500;
    border-radius: 37px;
    margin-right: 14px;
    font-size: 16px;
}

.weather_with_four_tabs .tab-pane.tab_pane_one {
    padding: 20px 16px 16px 16px;
    width: 100%;
    height: 280px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

/* .weather_with_four_tabs .tab-pane.tab_pane_one.img_cls_one {
    background-image: url(../assets/images/weather_bg.svg);
} */

/* .weather_with_four_tabs .tab-pane.tab_pane_one.img_cls_two {
    background-image: url(../assets/images/pollution_bg.svg);
} */

.relative_class_for_up {
    position: relative;
}

.weather_with_four_tabs .tab-pane.tab_pane_one h2 {
    font-weight: 300;
    font-size: 56px;
    /* color: #ffffff; */
    color: #336096;
    position: relative;
}

.weather_with_four_tabs .tab-pane.tab_pane_one h2.new_clr {
    color: #282828;
    font-weight: 400;
    padding-top: 13px;
}

.weather_time_zone_value {
    position: absolute;
    right: 18px;
    bottom: -30px;
    color: #9E9E9E;
    font-size: 11px;
    font-weight: 600;
}

.weather_with_four_tabs .tab-pane.tab_pane_one h2.meter_value {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.meter_niddle_area {
    position: absolute;
    left: 50%;
    bottom: 60px;
    transform: translateX(-50%) rotate(180deg);
    width: 5px;
    /* background: #ff000045; */
    height: 57%;
}

.meter_niddle_area .meter_niddle {
    width: 100%;
    height: 100%;
    position: absolute;
    /* background-image: linear-gradient(45deg, #0069eb, transparent, transparent, transparent); */
    top: 55px;
    left: 50%;
    transform: translateX(-50%) rotate(40deg);
    border-radius: 6px;
    transform-origin: 2px 3px;
    transition: transform 0.8s;
    transition-timing-function: cubic-bezier(0.65, 1.95, 0.03, 0.32);
}

.meter_niddle_area .meter_niddle::after {
    content: '';
    border-radius: 6px;
    height: 20%;
    width: 100%;
    background-color: #0069eb;
    position: absolute;
    left: 0;
    bottom: 0;
}

.weather_with_four_tabs .tab-pane.tab_pane_one h2 sup {
    font-size: 20px;
    top: -1.6em;
    font-weight: 900;
}

.weather_with_four_tabs .tab-pane.tab_pane_one h2 span {
    font-weight: 500;
    font-size: 16px;
    padding: 2px 0 0 0;
    color: #78777a;
    display: block;
}

.weather_with_four_tabs .tab-pane.tab_pane_one img.sun_cloud_img {
    position: absolute;
    right: 0;
    top: 15px;
    width: 87px;
}

.weather_with_four_tabs .tab-pane.tab_pane_one ul {
    padding: 0;
    margin: 20px 0 0;
    display: flex;
    align-items: center;
}

.weather_with_four_tabs .tab-pane.tab_pane_one ul li {
    list-style-type: none;
    font-weight: 500;
    font-size: 12px;
    color: rgba(51, 96, 150, 0.8);
    margin-right: 22px;
    position: relative;
}

.weather_with_four_tabs .tab-pane.tab_pane_one ul li img {
    width: 12px;
    margin-right: 6px;
    position: relative;
}

.weather_with_four_tabs .tab-pane.tab_pane_one .apexcharts-yaxis {
    display: none;
}

.weather_with_four_tabs .tab-pane.tab_pane_one .apexcharts-gridline {
    display: none;
}

.weather_with_four_tabs .tab-pane.tab_pane_one #SvgjsLine1525,
.weather_with_four_tabs .tab-pane.tab_pane_one #SvgjsLine1090,
.weather_with_four_tabs .tab-pane.tab_pane_one #SvgjsLine1569,
.weather_with_four_tabs .tab-pane.tab_pane_one #SvgjsLine1544,
.weather_with_four_tabs .tab-pane.tab_pane_one #SvgjsLine1539,
.weather_with_four_tabs .tab-pane.tab_pane_one #SvgjsLine1418,
.weather_with_four_tabs .tab-pane.tab_pane_one #SvgjsLine1806,
.weather_with_four_tabs .tab-pane.tab_pane_one #SvgjsLine1417,
.weather_with_four_tabs .tab-pane.tab_pane_one #SvgjsG1404,
.weather_with_four_tabs .tab-pane.tab_pane_one #SvgjsLine1546,
.weather_with_four_tabs .tab-pane.tab_pane_one #SvgjsLine1067 {
    display: none;
    border-color: transparent;
}

.tab_pane_one .speedometer {
    background-image: url(../assets/images/meter_icon.svg);
    height: 250px !important;
    background-position: 100% 10%;
    background-repeat: no-repeat;
    background-size: contain;
}

.tab_pane_one .speedometer .arc {
    display: none;
}

.tab_pane_one .speedometer .pointer {
    transform: translate(169px, 190px) !important;
    fill: #00907f9c !important;
}

.tab_pane_one .speedometer .current-value {
    font-weight: 600 !important;
    font-size: 60px !important;
    fill: rgba(0, 144, 127, 1) !important;
    transform: translate(6%, -13%);
}

.tab_pane_one .meter_text {
    position: absolute;
    text-align: center;
    left: 50%;
    bottom: 7%;
    transform: translateX(-50%);
}

.tab_pane_one .meter_text h4 {
    font-weight: 700;
    font-size: 16px;
    color: #00907F;
    padding-bottom: 3px;
    margin: 0;
}

.tab_pane_one .meter_text p {
    font-weight: 500;
    margin: 0;
    font-size: 12px;
    color: rgba(51, 96, 150, 0.6);
}

.tab_pane_one #SvgjsG1031,
.tab_pane_one #SvgjsLine1025,
.tab_pane_one #SvgjsLine1047,
.tab_pane_one #SvgjsLine1046,
.tab_pane_one #SvgjsLine1048,
.tab_pane_one #SvgjsLine1049,
.tab_pane_one #SvgjsLine1050,
.tab_pane_one #SvgjsLine1051,
.tab_pane_one #SvgjsLine1052,
.tab_pane_one #SvgjsLine1101 {
    display: none;
}

.tab_pane_one .location_area .live_indicator {
    position: absolute;
    display: block;
    width: 12px;
    height: 12px;
    background-color: #00D50442;
    border-radius: 100px;
    left: -16px;
    top: 1px;
}

.tab_pane_one .location_area .live_indicator::before {
    position: absolute;
    left: 3px;
    background-color: #00C400;
    animation: blink 1s infinite linear;
    top: 3px;
    width: 6px;
    height: 6px;
    content: '';
    border-radius: 100px;
}

@keyframes blink {
    from {
        opacity: 0;
        transition: 0.3s;
    }

    to {
        opacity: 1;
        transition: 0.3s;
    }
}

.weather_with_four_tabs .tab-pane.tab_pane_one.img_cls_three ul {
    margin: 0;
    padding: 0 6px 0 0;
    display: block;
    height: 275px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.tab_pane_one .apexcharts-grid-borders,
.tab_pane_one .apexcharts-xaxis {
    display: none;
}

.weather_with_four_tabs .tab-pane.tab_pane_one.img_cls_three ul li {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
    padding: 0;
}

.weather_with_four_tabs .tab-pane.tab_pane_one.img_cls_three ul li:nth-child(1) div,
.weather_with_four_tabs .tab-pane.tab_pane_one.img_cls_three ul li:nth-child(2) div {
    min-height: auto !important;
}

.weather_with_four_tabs .tab-pane.tab_pane_one.img_cls_three ul li img.red_charts_img {
    width: 130px;
    height: 24px;
}

.weather_with_four_tabs .tab-pane.tab_pane_one.img_cls_three ul li img.green_charts_img {
    width: 130px;
    height: 24px;
}

.weather_with_four_tabs .tab-pane.tab_pane_one.img_cls_three ul li .img_name {
    display: flex;
    align-items: center;
}

.weather_with_four_tabs .tab-pane.tab_pane_one.img_cls_three ul li .img_name img {
    width: 34px;
    margin-right: 12px;
}

/* .tab_pane_one ul::-webkit-scrollbar {
    display: none;
} */

.weather_with_four_tabs .tab-pane.tab_pane_one.img_cls_three ul li .img_name h4 {
    font-size: 14px;
    font-weight: 600;
    color: #212121;
}

.weather_with_four_tabs .tab-pane.tab_pane_one.img_cls_three ul li .img_name span {
    color: #9E9E9E;
    font-size: 12px;
    font-weight: 600;
}

.weather_with_four_tabs .tab-pane.tab_pane_one.img_cls_three ul li .img_rate h4 {
    font-size: 14px;
    font-weight: 600;
    color: #212121;
}

.weather_with_four_tabs .tab-pane.tab_pane_one.img_cls_three ul li .img_rate span {
    font-size: 12px;
    font-weight: 700;
}

.ant_select_design .ant-select-selector {
    padding: 2px 20px !important;
    height: auto !important;
    min-height: auto !important;
    display: block;
}

.ant_select_design .ant-select-selection-item {
    background-color: transparent !important;
    border-color: transparent !important;
}

.dash_board_box .report_eye_icon {
    margin: 8px;
    width: 14px;
}

.weather_with_four_tabs .tab-pane.tab_pane_one .speedo_meter_data ul {
    margin: 0;
    padding: 0;
    display: block;
}

.weather_with_four_tabs .tab-pane.tab_pane_one .speedo_meter_data ul li {
    list-style-type: none;
    display: flex;
    align-items: center;
    margin-bottom: 17px;
}

.weather_with_four_tabs .tab-pane.tab_pane_one .speedo_meter_data ul li img {
    width: 28px;
    height: 28px;
    margin-right: 8px;
}

.weather_with_four_tabs .tab-pane.tab_pane_one .speedo_meter_data ul li span {
    color: #768598;
    font-size: 13px;
    font-weight: 500;
}

.weather_with_four_tabs .tab-pane.tab_pane_one .speedo_meter_data ul li p {
    color: #212121;
    font-size: 14px;
    font-weight: 600;
}

.table_border_radius.vertical_scroll_table {
    margin-top: 24px;
}

.vertical_scroll_table_master {
    /* overflow: auto;
    overflow-y: scroll; */
    height: 487px;
}

.no_data_available {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.no_data_available_football {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.apexcharts-xaxis-tick {
    display: none !important;
}

.international_box h4 {
    color: #0A0A0A;
    font-size: 12px;
    font-weight: 600;
    padding: 0 0 0;
}

.international_box small {
    color: #0a0a0a;
    display: block;
    font-size: 13px;
    font-weight: 700;
}

.sport_widget_scroll_area {
    height: 205px;
    overflow-y: scroll;
    position: relative;
}

.weather_with_four_tabs .tab-pane.tab_pane_one.img_cls_three ul::-webkit-scrollbar-thumb,
.sport_widget_scroll_area::-webkit-scrollbar-thumb {
    background: transparent;
}

.weather_with_four_tabs .tab-pane.tab_pane_one.img_cls_three ul:hover::-webkit-scrollbar-thumb,
.sport_widget_scroll_area:hover::-webkit-scrollbar-thumb {
    background: #e0e0e0;
}



.sports_details_design li .international_box span {
    color: #939393;
    font-size: 11px;
    font-weight: 500;
    padding: 4px 0 10px;
    display: block;
}

.sports_details_design {
    margin: 0;
    padding: 0;
}

.sports_details_design li {
    list-style-type: none;
    padding: 16px 110px 16px 0;
    border-bottom: 1px solid #eee;
}

.sports_details_design li:last-child {
    border-bottom: none;
    padding-bottom: 0px;
}

.sports_details_design.live_sports_games li {
    display: flex;
    align-content: center;
    justify-content: space-between;
}

.sports_details_design li img {
    width: 32px;
    height: 32px;
    margin-right: 12px;
    object-fit: cover;
    border-radius: 100px;
}

.sports_details_design li h5 {
    color: #212121;
    font-size: 14px;
    font-weight: 600;
}

.sports_details_design li span {
    color: #BDBDBD;
    font-size: 14px;
    padding-right: 8px;
    font-weight: 500;
}

.sports_details_design li span.bottom_details_txt {
    color: #616161;
    font-size: 12px;
    font-weight: 600;
}

.sports_details_design li p {
    color: #3B3D91;
    font-size: 14px;
    font-weight: 700;
}

.sports_details_design li p small {
    font-size: 14px;
    color: #bdbdbd;
}

.hr_line_c {
    opacity: 1;
    border-color: #EEEEEE;
}

.sports_extra_details span {
    color: #616161;
    font-size: 12px;
    font-weight: 500;
    display: block;
}

.sports_extra_details span {
    font-size: 12px;
}

.weather_with_four_tabs .tab-pane.tab_pane_one .weather_cloud_img {
    position: relative;
    width: 95%;
    display: flex;
    justify-content: space-between;
    margin: 15px auto 0;
    left: 0;
}

.weather_with_four_tabs .tab-pane.tab_pane_one .weather_cloud_img li {
    margin: 0;
    position: relative;
    text-align: center;
}

/* .weather_with_four_tabs .tab-pane.tab_pane_one .weather_cloud_img li::after {
    position: absolute;
    content: '';
    left: 10px;
    top: 33px;
    width: 8px;
    height: 8px;
    background: white;
    border-radius: 100px;
} */

.weather_with_four_tabs .tab-pane.tab_pane_one .weather_cloud_img li img {
    width: 26px;
    margin: 4px 0;
    height: 26px;
}

.weather_with_four_tabs .tab-pane.tab_pane_one .weather_cloud_img li span {
    display: block;
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    color: #676767;
    padding-top: 0px;
}

.weather_with_four_tabs .tab-pane.tab_pane_one .weather_cloud_img li small {
    display: block;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    cursor: default;
    color: #8C8C8C;
    padding-top: 0;
}

.weather_with_four_tabs .tab-pane.tab_pane_one .weather_cloud_img li small:hover {
    color: #424242;
}

.chart_height {
    min-height: auto !important;
}

.chart_height .apexcharts-canvas {
    min-height: auto !important;
}

.no_weather_data {
    color: #979797;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    padding-top: 55px;
    position: relative;
}

.no_data_img {
    background-image: url(../assets/images/chart_no_data.svg);
    width: calc(100% - (16px));
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 95% 76%;
}

.apexcharts-tooltip.apexcharts-theme-light {
    border: none !important;
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    border: none !important;
    background: #fff !important;
    padding: 15px 10px 5px;
    font-size: 11px !important;
    font-weight: 600;
    color: #212121;
}

.apexcharts-tooltip-marker {
    width: 8px;
    height: 8px;
}

.apexcharts-tooltip-y-group {
    padding: 0 !important;
}

.apexcharts-svg.apexcharts-zoomable.hovering-zoom {
    cursor: default !important;
}

.apexcharts-xaxis text {
    font-size: 11px;
    font-family: "Nunito", sans-serif;
    fill: #bdbdbd;
}

.make_custom_chart_position {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-55%, -27%);
    width: 411px;
    height: 280px;
}

.make_custom_chart_position .apexcharts-svg,
.make_custom_chart_position foreignObject {
    width: 411px;
    height: 285px;
}

.weather_change_bg {
    position: absolute;
    left: 0;
    top: 0;
    height: 280px;
    width: 100%;
    object-fit: cover;
}

.make_custom_chart_position .apexcharts-text {
    display: none;
}

.make_custom_chart_position .apexcharts-legend-series {
    margin: 0 0 18px 4px !important;
    display: inline-flex;
}

.make_custom_chart_position .apexcharts-legend-series .apexcharts-legend-text {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 115px;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
}

.make_custom_chart_position .apexcharts-legend-series .apexcharts-legend-marker {
    top: 1px !important;
    left: -4px !important;
}


.make_custom_chart_position .apexcharts-tooltip .apexcharts-tooltip-y-group {
    display: flex;
    flex-direction: column;
}

.make_custom_chart_position .apexcharts-legend.apexcharts-align-center.apx-legend-position-right {
    right: 5px !important;
    top: 25px !important;
    padding: 0;
}

.ant-layout .ant-layout-sider-trigger {
    position: absolute !important;
    top: 75px;
    right: -6px;
    z-index: 1;
    height: 24px;
    width: 24px !important;
    color: #fff;
    line-height: inherit;
    text-align: center;
    background: #7375f2;
    border-radius: 100px;
    display: flex;
    cursor: pointer;
    transition: all 0.2s;
    align-items: center;
    justify-content: center;
}

.sidebar_area.new_side_bar.old_sidebar .anticon.anticon-right {
    background-image: url(../assets/images/sidebar_arrow.svg);
    width: 10px;
    height: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotate(180deg) translate(1px, 0px);
    transform-origin: 6px 5px;
}

.sidebar_area.new_side_bar.old_sidebar .anticon.anticon-left {
    background-image: url(../assets/images/sidebar_arrow.svg);
    width: 12px;
    height: 12px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    transform: translate(-1px, 0px);
}

.sidebar_area.new_side_bar.old_sidebar .anticon.anticon-right svg {
    display: none;
}

.sidebar_area.new_side_bar.old_sidebar .anticon.anticon-left svg {
    display: none;
}

.new_tab_design_for_dashboard .nav-tabs {
    border-bottom: 0px solid transparent;
}

.new_tab_design_for_dashboard .nav-link {
    color: #9E9E9E;
    font-size: 18px;
    font-weight: 500;
    padding: 0 0 4px;
}

.new_tab_design_for_dashboard .nav-tabs .nav-link:hover {
    border-color: transparent;
    color: #0A0A0A;
    border-bottom: 0px solid transparent;
}

.new_tab_design_for_dashboard .nav-tabs .nav-link.active {
    font-weight: 600;
    font-size: 18px;
    border: none;
    color: #0A0A0A;
    border-color: transparent;
    border-bottom: 2px solid #6366F1;
    text-align: center;
}

.diff_view_dashboard.new_tab_design_for_dashboard .nav-tabs .nav-link.active {
    border-bottom: none;
}

.new_tab_design_for_dashboard .nav-tabs li:nth-child(2) {
    margin-left: 24px;
}

.for_tabs_border {
    border-bottom: 1px solid #eee;
}

.diff_view_dashboard .for_tabs_border {
    border-bottom: none;
}

.diff_view_dashboard .table_border_radius.vertical_scroll_table {
    margin-top: 10px;
}

.for_three_dots .apexcharts-legend-text {
    padding-left: 18px !important;
    margin-right: 12px !important;
}

.ant-menu-submenu a {
    text-decoration: none;
}

.sports_details_design.live_match_details li {
    padding: 0;
}

.weather_with_four_tabs .tab-pane.tab_pane_one h2.no_data_color {
    color: #979797;
    font-weight: 400;
    font-size: 48px;
}

.sports_details_design.live_match_details li .hr_line_c {
    margin: 16px 0 12px;
}

.sports_details_design.live_match_details li .first_list_data {
    margin-bottom: 16px;
}

.sports_details_design.live_match_details li .first_list_data .over_det {
    color: #BDBDBD;
    font-size: 12px;
    font-weight: 500;
}

.sports_details_design.live_match_details li .first_list_data .match_run {
    color: #3B3D91;
    font-size: 14px;
    font-weight: 500;
}

.sports_details_design.live_match_details li .first_list_data .diff_team_run {
    color: #424242;
    font-size: 14px;
    font-weight: 500;
}

.sports_details_design.live_match_details li .first_list_data .players_out {
    padding: 0;
}

.match_overal_detals p {
    color: #616161;
    font-size: 12px;
    font-weight: 500;
    padding-bottom: 2px;
}

.match_overal_detals p span {
    color: #FC1B13;
    font-size: 12px;
    font-weight: 700;
}

.match_overal_detals span {
    color: #616161;
    font-size: 12px;
    font-weight: 500;
}

.match_overal_detals span strong {
    font-weight: 700;
}

.football_score {
    color: #212121;
    font-size: 14px;
    padding: 0 10px;
    font-weight: 600;
}

.hr_line_c_two {
    opacity: 1;
    border-color: #EEEEEE;
    margin: 24px 0;
}

.sports_details_design.live_match_details.football_match_area li {
    padding: 0 0 0 0;
}

.joined_input_fields .form-control:disabled {
    background-color: transparent;
}

.gray_btn_design .btn:hover {
    background-color: #e0e0e0;
    transition: 0.3s;
}

.ant-menu-light .ant-menu-item-selected,
.ant-menu-light>.ant-menu .ant-menu-item-selected {
    background-color: #eff0fe;
}

a {
    text-decoration: none;
}

.apexcharts-xaxis line {
    stroke: #f5f5f5;
}

.for_table_no_data_text p {
    color: #424242;
    font-size: 14px;
    font-weight: 500;
}

.for_table_no_data_text span {
    color: #9e9e9e;
    font-size: 14px;
    font-weight: 500;
}

.custom_country_drop_design .for_dashbaord_page.input-group-text {
    height: 30px !important;
    min-height: 30px !important;
}

.new_width_select .css-13cymwt-control .css-1dimb5e-singleValue {
    padding: 0;
    /* height: 30px !important;
    min-height: 30px !important; */
}

.new_width_select .css-t3ipsp-control .css-1dimb5e-singleValue {
    padding: 0px;
    /* height: 28px !important;
    min-height: 28px !important; */
}

.new_width_select .css-t3ipsp-control .css-15lsz6c-indicatorContainer {
    padding: 0 9px !important;
}

.new_width_select .css-t3ipsp-control,
.new_width_select .css-13cymwt-control {
    width: 130px;
    height: 30px !important;
    min-height: 30px !important;
}

.new_width_select .css-1dimb5e-singleValue {
    margin-left: 8px;
}

.custom_country_drop_design.diff-disabled_cls.new_width_select .css-olqui2-singleValue {
    margin-left: 8px;
}

.apexcharts-pie line,
.apexcharts-pie circle {
    stroke: #fff;
}

.custom_c {
    color: #282828;
    font-weight: 400;
    font-size: 56px;
}

.graph_areas_side h4.weather_loaction_text {
    color: #424242;
    font-size: 14px;
    font-weight: 500;
}

.graph_areas_side h4.weather_loaction_text svg {
    margin-right: 4px;
}

.on_hover_text_effect tbody tr td:first-child a svg {
    margin-left: 6px;
    opacity: 0;
    margin-top: -2px;
}

.on_hover_text_effect tbody tr td:first-child a {
    color: #2C6AB7 !important;
}

.on_hover_text_effect tbody tr td:first-child:hover a {
    color: #2C6AB7 !important;
    text-decoration: underline;
}

.on_hover_text_effect tbody tr td:first-child:hover a svg {
    opacity: 1;
}

.showing_wallet_amount {
    border-radius: 5px;
    border: 1px solid #E0E0E0;
    padding: 4px;
    margin-right: 12px;
}

.showing_wallet_amount svg.fund_icon_svg {
    margin-right: 6px;
}

.showing_wallet_amount span {
    color: #008500;
    font-size: 14px;
    font-weight: 600;
}

.showing_wallet_amount span.value_red {
    color: red;
}

.showing_wallet_amount button {
    width: 24px;
    height: 24px !important;
    border-radius: 4px !important;
    padding: 0 !important;
    margin-left: 11px;
    background-color: #6366f1;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.showing_wallet_amount button:hover {
    background-color: #595CD9;
}