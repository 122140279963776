.filter_area_design.new_m_p {
    margin: 0;
    margin-left: 8px;
    width: 540px;
    box-sizing: content-box;
    display: block;
}

.selected_item_area {
    width: 100%;
    height: 45px;
    border-radius: 8px 8px 0 0;
    background-color: #424242;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
}

.selected_item_area span {
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
}

.selected_item_area span small {
    color: #FFF;
    font-size: 14px;
    font-weight: 700;
}

.selected_item_area .two_btn_group .btn.border_btn {
    color: #FFF;
    font-size: 13px;
    font-weight: 600;
    border-radius: 6px;
    border: 1px solid #FFF;
    height: 28px;
    line-height: 10px;
    margin-right: 8px;
}

.selected_item_area .two_btn_group .btn.border_btn:hover {
    background-color: #eff0fe;
    color: #424242;
}

.selected_item_area .two_btn_group .btn.bg_btn {
    color: #FFF;
    font-size: 13px;
    font-weight: 600;
    border-radius: 6px;
    border: 1px solid #6366F1;
    height: 28px;
    background-color: #6366F1;
    line-height: 10px;
}

.selected_item_area .two_btn_group .btn.bg_btn:hover {
    background-color: #595CD9;
}

.selected_item_area .two_btn_group .btn.bg_btn svg {
    margin-right: 5px;
    transform: translateY(-1px);
}

.new_m_p h4 {
    color: #212121;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.new_mp_filter_d2 {
    position: relative;
    width: 100%;
    padding: 16px 0;
    /* border-bottom: 1px solid #E0E0E0; */
    height: auto;
}

.filter_with_icon label {
    color: #212121;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    display: flex;
    line-height: normal;
    align-items: center;
    position: relative;
}

.filter_with_icon label svg {
    margin-right: 6px;
}

.filter_with_icon label small {
    display: inline-flex;
    height: 20px;
    min-width: 20px;
    padding: 3px 6px;
    justify-content: center;
    align-items: center;
    border-radius: 23px;
    background: #FA8E02;
    color: #FFF;
    text-align: center;
    margin-left: 6px;
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
}

.drag_slider {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
}

.drag_slider span {
    color: #0A0A0A;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.drag_slider .ant-slider-horizontal {
    width: 95%;
}

.drag_slider .ant-slider .ant-slider-track {
    background-color: #6366F1;
}

.drag_slider .ant-slider .ant-slider-handle::after {
    box-shadow: 0 0 0 2px #595CD9;
}

.drag_slider .form-control {
    background-color: #fff;
}

.clear_filter_text {
    color: #F83A39 !important;
    font-style: normal;
    line-height: normal;
    position: absolute;
    right: 0;
    top: 0;
    margin: 15px 35px 0px 0px;
    z-index: 1000;
    cursor: pointer;
}

.filter_tree_select {
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.filter_tree_select .ant-select-selector {
    border-color: #6366f1;
    box-shadow: none;
}

.filter_tree_select .ant-select-open .ant-select-selector {
    border-radius: 8px;
    border-color: transparent !important;
    transition: 0s;
    border-width: 1px !important;
    outline: 4px solid transparent !important;
}

.filter_tree_select .ant-select-selection-item {
    background-color: #FFF4E2;
    border: 1px solid #FFF4E2;
    color: #2C6AB7;
    margin-right: 4px;
    height: 30px;
    display: flex;
    font-size: 12px;
    font-weight: 600;
    align-items: center;
}

.filter_tree_select .ant-select-selection-item .ant-select-selection-item-remove span svg path {
    fill: #9E9E9E;
}

.filter_tree_select .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    border-color: transparent;
    box-shadow: none;
    background-color: transparent;
}

.lift_study_text h3 {
    color: #0A0A0A;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.lift_types_checkbox .campaign_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.lift_types_checkbox .campaign_box .brand_lift_text {
    padding-left: 35px;
    color: #9E9E9E;
    font-size: 13px;
    font-weight: 600;
    line-height: normal;
}

.lift_types_checkbox .campaign_box .openrtb_text {
    transform: translateX(-79px);
}

.lift_types_checkbox .campaign_box span {
    color: #757575;
    text-align: right;
    font-size: 13px;
    line-height: normal;
    font-weight: 500;
}

.lift_types_checkbox.campaign_box_area input[type="checkbox"] {
    transform: translateY(-4px);
}

.lift_types_checkbox.campaign_box_area input[type="checkbox"]:checked+.campaign_box .brand_lift_text {
    color: #212121;
}

.survey_preview_area {
    border: 1px solid #E0E0E0;
    width: 896px;
    height: auto;
    margin-top: 20px;
    padding: 16px;
    border-radius: 8px;
}

.survey_preview_area .video_text {
    color: #595CD9;
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.survey_preview_area .pour_lesquelles_text {
    margin: 0;
    color: #212121;
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    padding: 8px 0 12px;
}

.survey_preview_area ul {
    margin: 0;
    padding: 0;
}

.survey_preview_area ul li {
    list-style-type: none;
    cursor: pointer;
    padding-bottom: 12px;
}

.survey_preview_area ul li:last-child {
    padding: 0;
}

.survey_preview_area ul li .form-check {
    display: flex;
    align-items: center;
    margin: 0;
}

.survey_preview_area ul li label {
    cursor: pointer;
    margin: 0;
    margin-left: 10px;
}

.survey_preview_area .checkbox_devider {
    opacity: 1;
    border-color: #E0E0E0;
    width: 100%;
    margin: 20px 0;
}

.delete_btn {
    position: absolute;
    right: 1px;
    top: 1px;
    background: #fff;
}



.newmediatable .ant-table-wrapper td.ant-table-column-sort {
    background-color: transparent;
}

.newmediatable .ant-table-header .ant-table-cell,
.newmediatable .ant-table-body tr td {
    white-space: nowrap;
    padding: 11.6px 2px;
    color: #424242;
    font-weight: 500;
    font-size: 13px;
}

.newmediatable .ant-table-tbody .ant-table-cell-row-hover {
    background-color: transparent !important;
}


.newmediatable .ant-table-tbody .ant-table-cell {
    padding: 11.6px 2px;
    width: auto;
    min-width: 75px;
}

.newmediatable .ant-table-tbody>tr.ant-table-row-level-0.ant-table-row-selected>td .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #6366F1;
    border: 1px solid #6366F1;
    width: 16px;
    height: 16px;
}

.newmediatable .ant-table-tbody>tr.ant-table-row-level-0>td.ant-table-cell-row-hover {
    background-color: transparent;
}

.newmediatable .ant-checkbox-wrapper .ant-checkbox:hover .ant-checkbox-inner {
    border: 1px solid #6366F1;
}

.newmediatable .ant-table-tbody>tr.ant-table-row-level-0.ant-table-row-selected>td .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #6366F1;
    border: 1px solid #6366F1;
    width: 16px;
    height: 16px;
}

.newmediatable .ant-checkbox .ant-checkbox-input:focus-visible+.ant-checkbox-inner {
    outline: none;
    outline-offset: 1px;
    transition: outline-offset 0s, outline 0s;
}

.newmediatable .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #6366F1;
    border-color: #6366F1;
}

.newmediatable .ant-checkbox-wrapper .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner,
.newmediatable .ant-checkbox-wrapper .ant-checkbox.ant-checkbox-indeterminate .ant-checkbox-inner:after {
    background-color: #6366F1;
    border: 1px solid #6366F1;
}

.newmediatable .ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #6366F1;
    border-color: transparent;
}

.newmediatable .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: #6366F1;
    border-color: transparent;
}

.newmediatable.ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected>th,
.newmediatable.ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected>td {
    background: #EFF0FE;
}

.newmediatable.ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected>td.ant-table-cell.ant-table-cell-row-hover {
    background: #EFF0FE !important;
}


/* /////////////////////////////////////////aayush css/////////////////////////////////////////////////////////////////////////////////////////////////////////// */

/* .new_media_border_bottom{
    border-bottom: 1px solid #E0E0E0  !important;
} */

.new_media_labels {
    max-height: 25px;
    margin-bottom: 5px;
    overflow-y: auto;
    cursor: default;
}

.new_media_labels_span_box {
    display: inline-flex;
    align-items: center;
    background-color: #f7f7e7;
    padding: 6px 8px;
    border-radius: 4px;
    margin-right: 5px;
    color: rgb(87, 86, 86) !important;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.new_media_labels_span_cross {
    cursor: pointer;
    margin-left: 8px;
    font-weight: bold;
    color: black;
}




.new_mp_filter_top_d2_d {
    position: relative;
    padding-bottom: 2px;
    border-bottom: 1px solid #E0E0E0 !important;
}

.new_mp_filter_d2_d {
    position: relative;
    width: 100%;
    padding: 16px 0;
    height: auto;
}

.new_mp_filter_pointer_d {
    cursor: pointer;
}

.dropdown-container {
    position: relative;
    display: inline-block;
    /* Ensures dropdown positions relative to this container */
}

.new_mp_filter_clear_d {
    color: #F83A39 !important;
    font-style: normal;
    line-height: normal;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1000;
    cursor: pointer;
    margin-top: 18px;
    margin-right: 35px;
}

.new_mp_filter_dropdown_icon_d {
    color: rgb(146, 140, 140);
    font-style: normal;
    line-height: normal;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1000;
    cursor: pointer;
    margin-top: 18px;
    margin-right: 15px;
}

.new_mp_filter_dropdown_d {
    border: 1px solid #E0E0E0;
    z-index: 1055 !important;
    cursor: default;
    background-color: white;
    transform: translateY(-6px);
    -webkit-transform: translateY(-6px);
    -moz-transform: translateY(-6px);
    -ms-transform: translateY(-6px);
    -o-transform: translateY(-6px);

    margin: 5px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    margin-top: 2px;
    height: 150px;
    overflow-y: auto;
}

.new_mp_filter_dropdown_d_above {
    border: 1px solid #E0E0E0;
    z-index: 1055 !important;
    cursor: default;
    background-color: white;
    transform: translateY(-6px);
    -webkit-transform: translateY(-6px);
    -moz-transform: translateY(-6px);
    -ms-transform: translateY(-6px);
    -o-transform: translateY(-6px);

    margin: 5px;
    position: absolute;
    top: -220% !important;
    left: 0;
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    margin-top: 2px;
    height: 150px;
    overflow-y: auto;
}

.new_mp_filter_dropdown_ul_d {
    list-style: none;
    padding: 0;
    margin: 0;
}

.new_mp_filter_dropdown_ul_sub_d {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-left: 15px;
}

.new_mp_filter_dropdown_checkbox_d {
    border: 2px solid #fff;
    padding: 1px 2px !important;
    position: relative;
    top: 2px;
    width: 16px;
    height: 16px;
    background-color: #ffffff;
    border: 1px solid #d9d9d9 !important;
    border-radius: 4px;
    cursor: pointer;
}

.new_mp_filter_dropdown_label_d {
    color: rgb(66, 62, 62) !important;
    padding: 0px 10px;
    line-height: 24px;
    cursor: pointer;
}