.addination_text {
    color: #7375F2;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.addination_text svg {
    margin-left: 5px;
}

.addination_text svg.up_arrow_css {
    margin-left: 1px;
}

.new_file_upload_design .inner_details_content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 80%;
}

.new_file_upload_design.new_file_upload_design_height .inner_details_content {
    position: relative;
    left: 0;
    top: 0;
    transform: translate(0);
}

.new_file_upload_design {
    width: 100%;
    height: 118px;
    border-radius: 8px;
    border: 1px dashed #BDBDBD;
    background: rgba(228, 242, 252, 0.30);
    position: relative;
}

.new_file_upload_design:hover {
    border: 1px dashed #757575;
    background: rgba(228, 242, 252, 0.60);
}

.new_file_upload_design input[type="file"] {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: relative;
    z-index: 9;
}

.new_file_upload_design.new_file_upload_design_height input[type="file"] {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    z-index: 9;
    left: 0;
    padding: 0;
    top: 0;
}

.new_file_upload_design .inner_details_content svg {
    margin-bottom: 9px;
}

.new_file_upload_design .inner_details_content h3 {
    color: #424242;
    font-size: 14px;
    font-weight: 600;
}

.new_file_upload_design .inner_details_content h3 span {
    color: #2C6AB7;
    font-weight: 500;
}

.new_file_upload_design .inner_details_content p {
    color: #BDBDBD;
    font-size: 12px;
    font-weight: 600;
}

.custom_container_table {
    width: -webkit-fill-available;
    width: -moz-available;
    margin-left: 44px;
}

/* .custom_data_table tbody tr td img.img_info_text {
    width: 16px;
    height: 16px;
    margin-right: 6px;
} */

.table_button_controls .addon_images_btn.default_btn.border_default_btn.btn {
    border-color: #6366F1;
    color: #6366F1;
    padding: 8px 15px;
    transition: 0.3s;
}

.table_button_controls .addon_images_btn.default_btn.border_default_btn.btn svg {
    margin: 0 4px 0 0;
}

.table_button_controls .addon_images_btn.default_btn.border_default_btn.btn svg path {
    transition: 0.3s;
}

.table_button_controls .addon_images_btn.default_btn.border_default_btn.btn:hover {
    color: #fff;
    background-color: #6366F1;
    transition: 0.3s;
}

.table_button_controls .addon_images_btn.default_btn.border_default_btn.btn:hover svg path {
    fill: #fff;
    transition: 0.3s;
}

.table_button_controls .clear_images_btn.default_btn.border_default_btn.btn {
    color: #F83A39;
    border: none;
    padding: 1px 8px 0;
}

.table_button_controls .clear_images_btn.default_btn.border_default_btn.btn:hover {
    color: #bd2727;
}

.after_select_checkbox_control p {
    color: #6366F1;
    font-size: 13px;
    font-weight: 500;
    padding-right: 8px;
    cursor: pointer;
}

.after_select_checkbox_control .reac_view_delete button {
    background-color: transparent;
    border-radius: 8px;
    border: 1px solid #D0D1FB;
    transition: 0.3s;
    margin-right: 8px;
    height: 36px;
    width: 36px;
}

.after_select_checkbox_control .reac_view_delete.for_hover_cls svg {
    margin: 5px 9px;
}

.after_select_checkbox_control .reac_view_delete.for_hover_cls {
    transition: 0.3s;
}

.after_select_checkbox_control .reac_view_delete.for_hover_cls:hover button {
    background-color: #6366F1;
    transition: 0.3s;
}

.after_select_checkbox_control .reac_view_delete.for_hover_cls:hover svg path {
    transition: 0.3s;
    fill: #fff;
}

.table_dashed_plus_btn {
    border-radius: 8px;
    border: 1px dashed #BDBDBD;
    padding: 0px 5px;
    margin-left: 4px;
    background-color: transparent;
    transition: 0.3s;
}

.table_dashed_plus_btn:hover {
    transition: 0.3s;
    border-color: #424242;
}

.table_dashed_plus_btn svg path {
    transition: 0.3s;
}

.table_dashed_plus_btn:hover svg path {
    transition: 0.3s;
    fill: #424242;
}

.upload_creatives_drop.custom_drop_design .css-13cymwt-control,
.upload_creatives_drop.custom_drop_design .css-t3ipsp-control {
    min-height: 36px !important;
    border: none !important;
    background-color: #6366F1;
    outline: none !important;
    width: 185 px;
    line-height: 1px;
    padding: 0 4px;
    cursor: pointer;
}

.upload_creatives_drop.custom_drop_design .css-13cymwt-control:hover,
.upload_creatives_drop.custom_drop_design .css-t3ipsp-control:hover {
    background-color: #595CD9;
}

.upload_creatives_drop.custom_drop_design .css-t3ipsp-control .css-1jqq78o-placeholder,
.upload_creatives_drop.custom_drop_design .css-13cymwt-control .css-1jqq78o-placeholder {
    color: #ffffff !important;
}

.upload_creatives_drop.custom_drop_design .css-t3ipsp-control .css-tj5bde-Svg,
.upload_creatives_drop.custom_drop_design .css-t3ipsp-control .css-1xc3v61-indicatorContainer,
.upload_creatives_drop.custom_drop_design .css-t3ipsp-control .css-15lsz6c-indicatorContainer,
.upload_creatives_drop.custom_drop_design .css-13cymwt-control .css-15lsz6c-indicatorContainer,
.upload_creatives_drop.custom_drop_design .css-13cymwt-control .css-1xc3v61-indicatorContainer {
    filter: contrast(10);
    background-image: url(../assets/images/all_down_arrow.svg);
    width: 26px;
    height: 26px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 0;
    transform: translate(-6px, 0px);
}

.upload_creatives_drop.custom_drop_design .css-15lsz6c-indicatorContainer svg,
.ant-select-suffix svg {
    display: none !important;
}

.modal_custom_datepicker_design .form-control {
    width: 326px;
    border-radius: 0px 8px 8px 0;
}

.modal_custom_datepicker_design .input-group-text {
    border-color: #E0E0E0;
}

.modal_custom_datepicker_design .full_input_border .input-group-text {
    border: 1px solid #6366f1;
    transition: 0.3s;
}

.change_lang {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal_popup_select.upload_creatives_drop.custom_drop_design .css-13cymwt-control,
.modal_popup_select.upload_creatives_drop.custom_drop_design .css-t3ipsp-control {
    margin-right: 12px;
    background-color: transparent;
    border: 1px solid #E0E0E0 !important;
    padding: 0;
    width: 210px;
}

.modal_popup_select.upload_creatives_drop.custom_drop_design .css-t3ipsp-control .css-1jqq78o-placeholder,
.modal_popup_select.upload_creatives_drop.custom_drop_design .css-13cymwt-control .css-1jqq78o-placeholder {
    color: #424242 !important;
}

.modal_popup_select.upload_creatives_drop.custom_drop_design .css-t3ipsp-control .css-tj5bde-Svg,
.modal_popup_select.upload_creatives_drop.custom_drop_design .css-t3ipsp-control .css-1xc3v61-indicatorContainer,
.modal_popup_select.upload_creatives_drop.custom_drop_design .css-t3ipsp-control .css-15lsz6c-indicatorContainer,
.modal_popup_select.upload_creatives_drop.custom_drop_design .css-13cymwt-control .css-15lsz6c-indicatorContainer,
.modal_popup_select.upload_creatives_drop.custom_drop_design .css-13cymwt-control .css-1xc3v61-indicatorContainer {
    filter: contrast(0);
}

.modal_popup_select.upload_creatives_drop.custom_drop_design .css-1dimb5e-singleValue svg {
    margin-right: 8px;
}

.modal_popup_select.upload_creatives_drop.custom_drop_design .css-1dimb5e-singleValue span {
    font-size: 14px;
    margin-left: 0px !important;
}

.form-check-input {
    cursor: pointer;
}

.filter_drop_design button:active,
.filter_drop_design button:focus {
    box-shadow: none;
    border-color: transparent !important;
}

.filter_drop_design .dropdown-menu {
    border: none;
    box-shadow: 1px 1px 20px 0px rgba(60, 60, 60, 0.16);
    min-width: auto;
}

.filter_drop_design .dropdown-item {
    color: #616161;
    font-size: 13px;
    font-weight: 500;
}

.filter_drop_design .dropdown-toggle::after {
    color: #BDBDBD;
    font-size: 18px;
}

.addnewrow {
    background-color: #FBFBFF;
}

.new_file_upload_design_btn input[type='file'] {
    left: 0;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
}

.new_file_upload_design_btn .inner_details_content {
    display: flex;
    align-items: center;
}

.video-react .video-react-video {
    position: relative !important;
}

.rotate_icon_effe {
    transform: rotate(180deg);
}

.table_drop_down {
    position: relative;
}

.table_drop_down .anticon.anticon-down {
    position: absolute;
    background-image: url(../assets/images/all_down_arrow.svg);
    width: 24px;
    height: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    left: -1px;
    top: -15px;
}

.table_drop_down .anticon.anticon-down svg {
    display: none;
}

.custom_danger_alert.alert.alert-danger {
    border-radius: 8px;
    border: 1px solid #FFC8CC;
    background: #FFE9EC;
    padding: 3px 12px 4px !important;
    height: auto;
}

.custom_danger_alert.alert.alert-danger span {
    color: #212121;
    font-size: 13px;
    font-weight: 500;
}

.custom_danger_alert.alert.alert-danger button {
    background-color: transparent;
    border: none;
}

.modal_customer__heading h4 {
    color: #0A0A0A;
    font-size: 14px;
    font-weight: 500;
    padding-top: 28px;
}

.modal_customer__heading hr {
    margin: 4px 0 12px;
    border-color: #E0E0E0;
    opacity: 1;
}

.table_input_fields {
    border-radius: 6px;
    border: 1px solid #eee;
    height: 32px;
    font-size: 13px;
    font-weight: 500;
}

.form-control.table_input_fields::placeholder {
    color: #e0e0e0;
}

.success_modal_popup_design .inner_modal_spacing {
    padding: 70px 0;
}

.success_modal_popup_design .btn-close {
    position: absolute;
    right: 20px;
    top: 18px;
    z-index: 9;
    opacity: 1;
    background-image: url(../assets/images/close_tag.svg);
}

.success_modal_popup_design svg {
    margin-bottom: 20px;
}

.success_modal_popup_design h2 {
    padding-bottom: 8px;
    color: #212121;
    font-size: 20px;
    font-weight: 600;
}

.success_modal_popup_design p {
    color: #616161;
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 32px;
}

.processing_text_line {
    border-radius: 8px;
    border: 1px solid #FECD81;
    background: #FFF4E2;
    padding: 6px 12px;
    color: #FA8E02;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
}