.main_data_table table thead tr th,
.main_data_table table tbody tr td {
    white-space: nowrap;
    width: max-content;
}

.main_data_table table tbody {
    height: 400px;
    min-height: 600px;
    overflow-y: scroll;
}

.css-1p3m7a8-multiValue {
    background-color: #E4F2FC !important;
    border: 1px solid #71B8EF;
    border-radius: 6px !important;
}

.css-wsp0cs-MultiValueGeneric {
    color: #2C6AB7 !important;
}

.delete_modal_popup_design .modal-body {
    padding: 30px 42px;
}

.delete_modal_popup_design .modal-body img {
    margin-bottom: 18px;
}

.delete_modal_popup_design .modal-body h2 {
    color: #0A0A0A;
    font-size: 20px;
    padding-bottom: 6px;
    font-weight: 600;
}

.delete_modal_popup_design .modal-body p {
    color: #757575;
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 27px;
}

.delete_modal_popup_design .modal-body p strong {
    display: block;
    color: #212121;
    font-size: 16px;
    font-weight: 600;
}

.delete_modal_popup_design .modal-body Button.border_cancel_btn {
    border-radius: 8px;
    border: 1px solid #757575;
    background-color: transparent;
    color: #757575;
    text-align: center;
    margin-right: 8px;
    padding: 8px 20px;
    font-size: 14px;
    font-weight: 600;
}

.delete_modal_popup_design .modal-body Button.border_cancel_btn:hover {
    border-color: #424242;
    color: #424242;
}

.delete_modal_popup_design .modal-body Button.fill_confirm_btn {
    border-radius: 8px;
    border: 1px solid #6366F1;
    background-color: #6366F1;
    color: #fff;
    text-align: center;
    padding: 8px 20px;
    font-size: 14px;
    font-weight: 600;
}

.delete_modal_popup_design .modal-body Button.fill_confirm_btn:hover {
    background-color: #595CD9;
}

.delete_modal_popup_design .user_delete_modal {
    position: absolute;
    right: 16px;
    top: 16px;
}

.second_modal_popup_design {
    background-color: #00000054;
}